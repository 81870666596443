module.exports = {
  bff: {
    URL: process.env.REACT_APP_DASHBOARD_BFF_URL,
    HOST: process.env.REACT_APP_DASHBOARD_BFF_HOST,
  },
  app: {
    ENVIRONMENT: process.env.REACT_APP_ENV,
    FILE_UPLOAD_BUCKET: process.env.REACT_APP_FILE_UPLOAD_BUCKET,
    PCP: {
      P1: process.env.REACT_APP_PCP_P1,
      P2: process.env.REACT_APP_PCP_P2
    },
    RME: process.env.REACT_APP_RME,
    SALESFORCE: process.env.REACT_APP_SALESFORCE,
    CDM_PUBLIC: process.env.REACT_APP_CDM_PUBLIC,
    OKTA_PASSWORD_RESET_URL: process.env.REACT_APP_OKTA_PASSWORD_RESET_URL
  },
};
