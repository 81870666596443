import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import { datadogRum } from '@datadog/browser-rum';
import { Redirect } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { setLoggedInUserDetails, authorize, setDatadogSecrets } from '../../service/userService';
import getAuthCookie from '../../service/getAuthCookie';
import { isEnterpriseCostReadOnlyUser } from '../../util/validator';
import { PERMISSIONS } from '../../helpers/constants';
import config from '../../config';
import { UNABLE_TO_ACCESS_CDM_DASHBOARD, UNAUTHORIZED_TO_ACCESS_CDM_DASHBOARD } from '../../helpers/messageConstant';
import { store } from '../../internals/store';

/**
 * Login page
 *
 * @author hgun0888
 */

const loaderCSS = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCookie: true,
      isAuthenticated: false,
      loading: true,
      error: { show: false, message: '' }
    };
  }

  async componentDidMount() {
    try {
      await setDatadogSecrets();
      const isCookie = await getAuthCookie();
      if (isCookie) {
        await setLoggedInUserDetails();
        await this.postLoginAction();
      } else {
        this.setState({ isCookie: false });
      }
    } catch (e) {
      this.setState({ isAuthenticated: false, loading: false, error: { show: true, message: UNABLE_TO_ACCESS_CDM_DASHBOARD } });
    }
  }

  async postLoginAction() {
    if (authorize([PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD, PERMISSIONS.VIEW_ENTERPRISE_COST_DATA])) {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({
        isAuthenticated: false,
        loading: false,
        error: { show: true, message: UNAUTHORIZED_TO_ACCESS_CDM_DASHBOARD }
      });
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const {
      disableDashboardRedirection, roles, networkId, email,
    } = store.getState().userReducer;
    const { datadogSecrets } = store.getState().secretsReducer;
    if (!this.state.isCookie) {
      return <Redirect to='/login' />;
    }
    if (this.state.isAuthenticated) {
      if (datadogSecrets !== null) {
        // Initialize Datadog RUM
        datadogRum.init({
          applicationId: datadogSecrets.applicationId,
          clientToken: datadogSecrets.clientToken,
          site: 'datadoghq.com',
          service: 'cdm-dashboard',
          env: config.app.ENVIRONMENT,
          sessionSampleRate: 100,
          trackUserInteractions: true
        });
        // Set User Details in RUM
        datadogRum.setUser({
          id: networkId,
          name: email,
          email,
        });
      }
      if (disableDashboardRedirection) {
        return <Redirect to='/welcome' />;
      }
      if (isEnterpriseCostReadOnlyUser(roles)) {
        return <Redirect to='/enterprise-cost-view' />;
      }
      return <Redirect to={from} />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            {!this.state.loading && !this.state.isAuthenticated
              ? <div style={{ textAlign: 'center' }}>
                <i className="fa fa-exclamation-circle fa-4x" aria-hidden="true" />
                <h4>{this.state.error.message}</h4>
              </div>
              : <p>Please Wait</p>}
          </Row>
          <Row className="justify-content-center">
            <div className='sweet-loading' id={'login_loader_div'}>
              <RingLoader
                className={loaderCSS}
                sizeUnit={'px'}
                size={80}
                color={'#123abc'}
                loading={this.state.loading}
              />
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.any
};

export default Login;
