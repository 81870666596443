import React, { Component } from 'react';
import PropTypes from 'prop-types';
/**
 * Custom Quick Link
 *
 * @author skit1169
 */

class QuickLink extends Component {
  render = () => (
    <div
      className='quick-link'
      style={{
        color: 'rgb(9 136 237)',
        fontWeight: 'bold',
        marginBottom: '0.3rem',
        fontSize: '15px',
        textAlign: 'center',
        textDecoration: 'underline',
        cursor: 'pointer',
        ...this.props.divStyles,
      }}
      onClick={() => {
        window.open(this.props.url);
      }}
    > {this.props.text} </div>
  )
}

QuickLink.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  divStyles: PropTypes.object,
};

export default QuickLink;
