/**
 * Created by hgun0888
 * */

/**
 * Action Types
 */

export const REQUEST_ALL_VENDORS = 'REQUEST_ALL_VENDORS';
export const RECEIVE_ALL_VENDORS = 'RECEIVE_ALL_VENDORS';

export const SELECT_SUVC_ASSOCIATION = 'SELECT_SUVC_ASSOCIATION';
export const SEARCH_VENDORS = 'SEARCH_VENDORS';
export const SELECT_VENDOR_ASSOCIATION = 'SELECT_VENDOR_ASSOCIATION';
export const RESET_SELECTED_SUVCS = 'RESET_SELECTED_SUVCS';
export const REQUEST_ALL_OPCOS = 'REQUEST_ALL_OPCOS';
export const RECEIVE_ALL_OPCOS = 'RECEIVE_ALL_OPCOS';
export const RECEIVE_OPCO_FETCH_ERROR = 'RECEIVE_OPCO_FETCH_ERROR';

export const requestAllVendors = () => (
  {
    type: REQUEST_ALL_VENDORS,
  });

export const receiveAllVendors = (data) => (
  {
    type: RECEIVE_ALL_VENDORS,
    data
  });

export const selectSuvcAssociation = (row, value) => ({
  type: SELECT_SUVC_ASSOCIATION,
  data: {
    row, value
  }
});

export const searchVendors = (value, suvcMappingList) => ({
  type: SEARCH_VENDORS,
  data: {
    value, suvcMappingList
  }
});

export const selectVendorAssociation = (row, value) => ({
  type: SELECT_VENDOR_ASSOCIATION,
  data: {
    row, value
  }
});

export const resetSelectedSuvcs = () => (
  {
    type: RESET_SELECTED_SUVCS
  });

export const requestAllOpcos = () => (
  {
    type: REQUEST_ALL_OPCOS,
  });

export const receiveAllOpcos = (data) => (
  {
    type: RECEIVE_ALL_OPCOS,
    data
  });
