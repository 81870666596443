import initialState from './initialState';
import {
  REQUEST_ENTERPRISE_COST_MASTER_DATA,
  RECEIVE_ENTERPRISE_COST_MASTER_DATA,
  REQUEST_ENTERPRISE_COST_DATA,
  RECEIVE_ENTERPRISE_COST_DATA,
  CLEAR_ENTERPRISE_COST_AND_ITEM_DATA,
} from '../actions/enterpriseCostActions';
import {
  RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH,
} from '../actions/errorDialogActions';

/**
 * Reducer related to enterprise cost
 * @author skit1169
 */
export default function enterpriseCostReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.enterpriseCostData;
  }

  if (type === REQUEST_ENTERPRISE_COST_MASTER_DATA) {
    return { ...state, loading: true };
  }

  if (type === RECEIVE_ENTERPRISE_COST_MASTER_DATA) {
    return {
      ...state,
      sites: [...data],
      loading: false,
    };
  }

  if (type === REQUEST_ENTERPRISE_COST_DATA) {
    return { ...state, loading: true };
  }

  if (type === RECEIVE_ENTERPRISE_COST_DATA) {
    return {
      ...state, itemHeader: data.itemHeader, costData: { ...data.costData, houseName: data.houseName }, loading: false
    };
  }

  if (type === CLEAR_ENTERPRISE_COST_AND_ITEM_DATA) {
    return {
      ...state, itemHeader: {}, costData: {}, loading: false
    };
  }

  if (type === RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH) {
    return {
      ...state, itemHeader: {}, costData: {}, loading: false
    };
  }

  return state;
}
