/**
 * Created by ysum5308
 * */
import initialState from './initialState';
import {
  REQUEST_RECENT_ACTIVITY_DATA,
  RECEIVE_RECENT_ACTIVITY_DATA,
  RECEIVE_ERROR_IN_RECENT_ACTIVITY_DATA_FETCH,
  PRESERVE_RECENT_ACTIVITY_FILTER_ATTRIBUTES,
  RESET_RECENT_ACTIVITY_FILTER_RETENTION,
  RETAIN_RECENT_ACTIVITY_FILTER
} from '../actions/workspaceActions';

export default function recentActivityReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.workspace.recentActivity;
  }

  if (type === REQUEST_RECENT_ACTIVITY_DATA) {
    return { ...state, loading: true, error: { show: false, message: '' } };
  }

  if (type === RECEIVE_RECENT_ACTIVITY_DATA) {
    return { ...state, data: data.recentActivities, loading: false };
  }

  if (type === RECEIVE_ERROR_IN_RECENT_ACTIVITY_DATA_FETCH) {
    return { ...state, loading: false, error: { show: true, message: data } };
  }

  if (type === PRESERVE_RECENT_ACTIVITY_FILTER_ATTRIBUTES) {
    return { ...state, filteredAttributes: data };
  }

  if (type === RETAIN_RECENT_ACTIVITY_FILTER) {
    return { ...state, isFilterRequired: true };
  }

  if (type === RESET_RECENT_ACTIVITY_FILTER_RETENTION) {
    return { ...state, isFilterRequired: false, filteredAttributes: [] };
  }

  return state;
}
