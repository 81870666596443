/**
 * Created by hgun0888
 * 12/3/18
 * */

/**
 * Action Types
 */
export const REQUEST_FILE_TRANSFER_DETAILS = 'REQUEST_FILE_TRANSFER_DETAILS';
export const RECEIVE_FILE_TRANSFER_DETAILS = 'RECEIVE_FILE_TRANSFER_DETAILS';

/**
 * Actions
 */
export const requestFileTransferDetails = (serverType, userGroups) => (
  { type: REQUEST_FILE_TRANSFER_DETAILS, data: { serverType, userGroups } });
export const receiveFileTransferDetails = (data) => ({ type: RECEIVE_FILE_TRANSFER_DETAILS, data });
