import initialState from './initialState';
import {
  HIDE_MODAL_FOR_SELECTED_USER,
  RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER,
  SHOW_MODAL_FOR_SELECTED_USER,
} from '../actions/userManagementActions';
import { RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER } from '../actions/errorDialogActions';

/**
 *  User Profile data
 */
export default function userProfileReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.selectedUser;
  }

  if (type === RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER) {
    return { ...state, profile: data };
  }

  if (type === SHOW_MODAL_FOR_SELECTED_USER) {
    return { ...state, networkId: data.networkId, show: true };
  }

  if (type === HIDE_MODAL_FOR_SELECTED_USER || type === RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER) {
    return initialState.selectedUser;
  }

  return state;
}
