import initialState from './initialState';
import {
  RECEIVE_USERS,
} from '../actions/userManagementActions';

export default function usersReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.users;
  }

  if (type === RECEIVE_USERS) {
    return { ...state, ...data };
  }

  return state;
}
