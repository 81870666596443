import React, { Component } from 'react';
import { MoonLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const loaderCSS = css`
  position: fixed;
  display: block;
  width:'100%';
  top: 52%;
  left: 50%;
  z-index:100000;
  margin: 0 auto;
  border-color: black;
  font-weight:1000;
  border: 141px;
`;

class SpinnerLoader extends Component {
  render() {
    return (
      this.props.loading.type === 'spinner' && this.props.loading.show === true
        ? <div className="sweet-loading"> <MoonLoader
          css={loaderCSS}
          sizeUnit={'px'}
          size={40}
          color={'navy'}
          loading={this.props.loading.show}
        /> </div> : null
    );
  }
}

export default SpinnerLoader;

SpinnerLoader.propTypes = {
  loading: PropTypes.object
};
