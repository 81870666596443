import CookieProxy from '../externals/cookieProxy';
import { store } from '../internals/store';
import { authCookie } from '../internals/actions/cookieActions';

async function getAuthCookie() {
  const isCookie = await CookieProxy.fetchCookie();
  store.dispatch(authCookie(isCookie));
  return isCookie;
}

export default getAuthCookie;
