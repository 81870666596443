/**
 * Workspace search reducers
 *
 * @author kbas1062
 * */
import initialState from './initialState';
import {
  REQUEST_WORKSPACE_SEARCH_DATA,
  RECEIVE_WORKSPACE_SEARCH_DATA,
  RECEIVE_ERROR_IN_WORKSPACE_SEARCH_DATA_FETCH,
  UPDATE_WORKSPACE_SEARCH_REQUEST_IDS,
  UPDATE_WORKSPACE_SEARCH_REQUEST_FILTERS,
  UPDATE_WORKSPACE_SEARCH_TYPE,
  UPDATE_WORKSPACE_SEARCH_VALIDITY,
  RESET_WORKSPACE_SEARCH,
  UPDATE_WORKSPACE_SEARCH_PRESERVE_STATUS
} from '../actions/workspaceActions';

export default function workspaceSearchReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.workspace.search;
  }

  if (type === REQUEST_WORKSPACE_SEARCH_DATA) {
    return { ...state, loading: true, error: { show: false, message: '' } };
  }

  if (type === RECEIVE_WORKSPACE_SEARCH_DATA) {
    return { ...state, data: data.requests, loading: false };
  }

  if (type === RECEIVE_ERROR_IN_WORKSPACE_SEARCH_DATA_FETCH) {
    return { ...state, loading: false, error: { show: true, message: data } };
  }

  if (type === UPDATE_WORKSPACE_SEARCH_REQUEST_IDS) {
    return { ...state, requestIds: data };
  }

  if (type === UPDATE_WORKSPACE_SEARCH_REQUEST_FILTERS) {
    return { ...state, searchFilters: data };
  }

  if (type === UPDATE_WORKSPACE_SEARCH_TYPE) {
    return { ...state, searchType: data };
  }

  if (type === UPDATE_WORKSPACE_SEARCH_VALIDITY) {
    return { ...state, isInValid: data };
  }

  if (type === UPDATE_WORKSPACE_SEARCH_PRESERVE_STATUS) {
    return { ...state, preserve: data };
  }

  if (type === RESET_WORKSPACE_SEARCH) {
    return initialState.workspace.search;
  }

  return state;
}
