/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * Action Types
 */

export const REQUEST_REPORTS_LOG = 'REQUEST_REPORTS_LOG';
export const RECEIVE_REPORTS_LOG = 'RECEIVE_REPORTS_LOG';

export const REQUEST_COST_AUDIT_REPORT_DATA = 'REQUEST_COST_AUDIT_REPORT_DATA';
export const RECEIVE_COST_AUDIT_REPORT_DATA = 'RECEIVE_COST_AUDIT_REPORT_DATA';

export const REQUEST_REPORT_METADATA = 'REQUEST_REPORT_METADATA';
export const RECEIVE_REPORT_METADATA = 'RECEIVE_REPORT_METADATA';

export const REQUEST_REPORT_TEMPLATE_DETAILS = 'REQUEST_REPORT_TEMPLATE_DETAILS';
export const RECEIVE_REPORT_TEMPLATE_DETAILS = 'RECEIVE_REPORT_TEMPLATE_DETAILS';

export const RESET_SELECTED_REPORT_TEMPLATE_DETAILS = 'RESET_SELECTED_REPORT_TEMPLATE_DETAILS';

export const SAVE_REPORT_TEMPLATE = 'SAVE_REPORT_TEMPLATE';
export const RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS = 'RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS';

export const DELETE_REPORT_TEMPLATE = 'DELETE_REPORT_TEMPLATE';
export const RECEIVE_REPORT_TEMPLATE_DELETE_STATUS = 'RECEIVE_REPORT_TEMPLATE_DELETE_STATUS';

export const CHANGE_COST_AUDIT_REPORT_TEMPLATE_COLUMNS = 'CHANGE_COST_AUDIT_REPORT_TEMPLATE_COLUMNS';

export const requestReportsLog = ({
  username, reportName, reportType, startTime, endTime, page, pageSize, sorted, userGroups
}) => (
  {
    type: REQUEST_REPORTS_LOG,
    data: {
      username,
      reportName,
      reportType,
      startTime,
      endTime,
      page,
      pageSize,
      sorted,
      userGroups
    }
  });

export const receiveReportsLog = (data) => (
  {
    type: RECEIVE_REPORTS_LOG,
    data
  });

export const requestReportMetaData = (data) => (
  {
    type: REQUEST_REPORT_METADATA,
    data
  });

export const receiveReportMetaData = (data) => (
  {
    type: RECEIVE_REPORT_METADATA,
    data
  });

export const receiveCostAuditReportData = (data) => (
  {
    type: RECEIVE_COST_AUDIT_REPORT_DATA,
    data
  });

export const requestCostAuditReportData = (data) => (
  {
    type: REQUEST_COST_AUDIT_REPORT_DATA,
    data
  });

export const requestReportTemplateDetails = (data) => (
  {
    type: REQUEST_REPORT_TEMPLATE_DETAILS,
    data
  });

export const receiveReportTemplateDetails = (data) => (
  {
    type: RECEIVE_REPORT_TEMPLATE_DETAILS,
    data
  });

export const resetSelectedReportTemplateDetails = () => (
  {
    type: RESET_SELECTED_REPORT_TEMPLATE_DETAILS
  });

export const saveReportTemplate = (data) => (
  {
    type: SAVE_REPORT_TEMPLATE,
    data
  });

export const receiveReportTemplatePersistStatus = (data) => (
  {
    type: RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS,
    data
  });

export const deleteReportTemplate = (data) => (
  {
    type: DELETE_REPORT_TEMPLATE,
    data
  });

export const receiveReportTemplateDeleteStatus = (data) => (
  {
    type: RECEIVE_REPORT_TEMPLATE_DELETE_STATUS,
    data
  });
