import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to enterprise cost
 * @author skit1169
 */

class EnterpriseCostProxy {
  static async fetchEnterpriseCostData(data) {
    const { item, house } = data;
    const config = {
      url: `${URLS.ENTERPRISE_COST_URL}/item-cost-data`,
      method: HTTP_METHODS.GET,
      params: { item, house }
    };
    const response = await axios(config);
    return response.data;
  }
}

export default EnterpriseCostProxy;
