import UsersProxy from '../externals/usersProxy';
import { store } from '../internals/store';
import {
  logInSuccess, logoutUser, fetchDatadogSecrets, fetchDatadogSecretsError,
} from '../internals/actions/userManagementActions';
import { URLS } from '../helpers/constants';

export async function setLoggedInUserDetails() {
  const userData = await UsersProxy.fetchLoggedInUserDetails();
  store.dispatch(logInSuccess(userData));
}

export async function setDatadogSecrets() {
  try {
    const ddSecrets = await UsersProxy.fetchDatadogSecrets();
    store.dispatch(fetchDatadogSecrets(ddSecrets));
  } catch (e) {
    store.dispatch(fetchDatadogSecretsError(e));
  }
}

export function logout() {
  store.dispatch(logoutUser());
  window.location.assign(`${URLS.LOGOUT_URL}?redirect_url=${window.location.origin}/login`);
}

export function authorize(requiredPermissions) {
  const { permissions } = store.getState().userReducer;
  return requiredPermissions.some((requiredPermission) => permissions.some((permission) => {
    if (requiredPermission === permission) {
      return true;
    }
    return false;
  }));
}
