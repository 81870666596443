/**
 * Enterprise Cost Actions
 * @author skit1169
 */

/**
 * Action Types
 */
export const REQUEST_ENTERPRISE_COST_MASTER_DATA = 'REQUEST_ENTERPRISE_COST_MASTER_DATA';
export const RECEIVE_ENTERPRISE_COST_MASTER_DATA = 'RECEIVE_ENTERPRISE_COST_MASTER_DATA';
export const REQUEST_ENTERPRISE_COST_DATA = 'REQUEST_ENTERPRISE_COST_DATA';
export const RECEIVE_ENTERPRISE_COST_DATA = 'RECEIVE_ENTERPRISE_COST_DATA';
export const CLEAR_ENTERPRISE_COST_AND_ITEM_DATA = 'CLEAR_ENTERPRISE_COST_AND_ITEM_DATA';

/**
 * Actions
 */
export const requestEnterpriseCostMasterData = () => ({
  type: REQUEST_ENTERPRISE_COST_MASTER_DATA,
});

export const receiveEnterpriseCostMasterData = (data) => ({
  type: RECEIVE_ENTERPRISE_COST_MASTER_DATA,
  data
});

export const requestEnterpriseCostData = (data) => ({
  type: REQUEST_ENTERPRISE_COST_DATA,
  data
});

export const receiveEnterpriseCostData = (data) => ({
  type: RECEIVE_ENTERPRISE_COST_DATA,
  data
});

export const clearEnterpriseCostAndItemData = () => ({
  type: CLEAR_ENTERPRISE_COST_AND_ITEM_DATA
});
