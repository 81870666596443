import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'mdbreact';
import {
  Card, CardBody, Button,
} from 'reactstrap';
import {
  getBrowserType,
} from '../../util/browserDetails';
import { CookieSettingsPage } from './cookieSettingsPage';

const COOKIE_ENABLE_STEP_TEXT = 'Please follow these steps to enable cookies';
/**
 * CookiePolicy
 * @author skit1169
 * */
class CookiePolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browserName: getBrowserType(),
      showCookieGuideModel: false
    };
  }

  hideCookiePolicyModel = () => {
    this.props.hideModal();
  }

  showCookieSettingsGuideModel = () => {
    this.setState({ showCookieGuideModel: true }, () => this.props.hideModal());
  }

  hideCookieSettingsGuideModel = () => {
    this.setState({ showCookieGuideModel: false });
  }

  render() {
    return (
      <React.Fragment>
        <Modal id={this.props.id} isOpen={this.props.isOpen} toggle={this.hideCookiePolicyModel} size='md' centered>
          <ModalBody>
            <Card style={{ color: '#585858', fontSize: '13px', borderRadius: '8px' }}>
              <CardBody style={{ position: 'relative' }}>
                <p style={{
                  color: '#23282c', fontWeight: 'bold', marginBottom: '0.3rem', fontSize: '15px', textAlign: 'center'
                }}>
                  Cookie Policy
                </p>
                <div style={{
                  color: '#23282c', fontWeight: '600', marginTop: '1rem', textAlign: 'center', marginBottom: '3rem',
                }}>
                  <p>Cookies allow successful login to the application and make our website work properly. By allowing cookies, you will get the best experience.
                  </p>
                  <p style={{
                    color: '#23282c', fontWeight: 'bold', marginBottom: '0.3rem', fontSize: '15px', textAlign: 'center'
                  }}>
                    Enable Cookies in Your Browser
                  </p>
                  <p style={{ fontSize: '14px', }}>Make sure you select the
                    <span style={{ color: 'green', fontWeight: 'bold' }}> &quot;Allow cookies&quot; </span>
                    option from the browser settings, for a<span style={{ color: 'green', fontWeight: 'bold' }}> successful login </span>
                    for the CDM application.
                  </p>
                  <div
                    id='cookieSettingGuideBtn'
                    style={{
                      fontWeight: 'bold',
                      color: 'rgb(9 136 237)',
                      marginBottom: '0.3rem',
                      fontSize: '15px',
                      textAlign: 'center',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={this.showCookieSettingsGuideModel}
                  >
                    {COOKIE_ENABLE_STEP_TEXT}
                  </div>
                </div>
                <div style={{
                  bottom: '1rem', right: '1rem', marginLeft: 'auto', position: 'absolute', marginTop: '1rem'
                }}>
                  <Button type='button' color='secondary' className='btn-md'
                    onClick={this.hideCookiePolicyModel}
                    id='close_cookie_policy_model'
                    style={{
                      padding: '3px 0px', width: '70px', height: '30px', marginLeft: '1rem'
                    }}>
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal >
        {this.state.showCookieGuideModel
          && <CookieSettingsPage
            id='cookieSettingsGuide'
            hideCookieGuideModal={this.hideCookieSettingsGuideModel}
            isOpen={this.state.showCookieGuideModel}
          />}
      </React.Fragment>
    );
  }
}

CookiePolicy.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func,
};

export default CookiePolicy;
