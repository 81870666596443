import initialState from './initialState';
import {
  AUTH_COOKIE,
} from '../actions/cookieActions';

/**
 *  Cookie data
 */
export default function cookieReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.isCookie;
  }

  if (type === AUTH_COOKIE) {
    return { ...state, isCookie: data };
  }

  return state;
}
