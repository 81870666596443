import initialState from './initialState';
import {
  CHANGE_USER_GROUP_SELECTION_STATUS,
  LOG_IN_SUCCESS,
  LOG_OUT,
  RECEIVE_GROUP_PERSIST_STATUS,
  RECEIVE_USER_PROFILE_DETAILS,
  RECEIVE_USER_GROUP_ASSOCIATION_DETAILS,
  SWITCH_USER_GROUPS,
} from '../actions/userManagementActions';
import {
  RECEIVE_ERROR_IN_USER_GROUP_ASSOCIATION_DETAILS_FETCH
} from '../actions/errorDialogActions';
import { PERMISSIONS } from '../../helpers/constants';
/**
 *  Logged In User data
 */

function addGrouptoState(state, data) {
  data.group.id = state.groups.length;
  state.groups.push(data.group);
  return { ...state, groups: state.groups.slice() };
}

function verifyAndAddGroup(state, data) {
  const { groups } = state;
  if (state.permissions.includes(PERMISSIONS.VIEW_ALL_TRANSACTIONS)) {
    return addGrouptoState(state, data);
  }

  if (state.permissions.includes(PERMISSIONS.ASSOCIATED_TRANSACTION_VIEW) && data.parentGroupCode) {
    const groupCodes = groups.map(({ code }) => code);
    if (groupCodes.includes(data.parentGroupCode)) {
      return addGrouptoState(state, data);
    }
    return state;
  }
  return state;
}

export default function userReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.user;
  }

  if (type === LOG_IN_SUCCESS) {
    const permissions = [];
    const roles = [];
    if (data && data.data) {
      if (data.data.roles) {
        data.data.roles.forEach((role) => {
          roles.push(role.code);
          permissions.push(...role.permissions.map((value) => value.code));
        });
      }
      return {
        ...state,
        active: data.active,
        roles,
        permissions,
        groups: data.data.groups,
        networkId: data.data.networkId,
        email: data.data.email,
        phone: data.data.phone,
        userType: data.data.userType,
        disableDashboardRedirection: data.disableDashboardRedirection,
        error: { show: false, message: '' }
      };
    }
  }

  if (type === SWITCH_USER_GROUPS) {
    return { ...state, selectedGroups: data };
  }

  if (type === CHANGE_USER_GROUP_SELECTION_STATUS) {
    return { ...state, isUserGroupSelectionEnabled: data };
  }

  if (type === RECEIVE_GROUP_PERSIST_STATUS) {
    return verifyAndAddGroup(state, data);
  }

  if (type === RECEIVE_USER_PROFILE_DETAILS) {
    return { ...state, profile: data };
  }

  if (type === RECEIVE_USER_GROUP_ASSOCIATION_DETAILS) {
    return { ...state, groups: data, error: { show: false, message: '' } };
  }

  if (type === RECEIVE_ERROR_IN_USER_GROUP_ASSOCIATION_DETAILS_FETCH) {
    return { ...state, error: { show: true, message: data } };
  }

  if (type === LOG_OUT) {
    return { ...state, active: false, permissions: [] };
  }

  return state;
}
