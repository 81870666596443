/**
 * Get the browser details
 *
 * @author skit1169
 */
export const FIREFOX = 'Firefox';
export const MICROSOFT_EDGE = 'Microsoft_Edge';
export const CHROME = 'Chrome';
export const SAFARI = 'Safari';
export const UNIDENTIFIED = 'Unidentified';

export function getBrowserType() {
  const browserData = navigator.userAgent;
  let browserType = '';

  if (browserData.indexOf(FIREFOX) > -1) {
    browserType = FIREFOX;
  } else if (browserData.indexOf('Edg') > -1) {
    browserType = MICROSOFT_EDGE;
  } else if (browserData.indexOf(CHROME) > -1) {
    browserType = CHROME;
  } else if (browserData.indexOf(SAFARI) > -1) {
    browserType = SAFARI;
  } else {
    browserType = UNIDENTIFIED;
  }
  return browserType;
}

export const browserCookieDetails = {
  Chrome: [{
    subHeading: 'In your browser, click on the "Customize and Control" button. ',
    subText: 'In the top-right corner of the Chrome window you\'ll see the Customize menu icon ',
    icon: 'fa fa-ellipsis-v'
  },
  {
    subHeading: 'Select the "Settings" menu item.',
    subText: 'Once clicked, the Settings screen will appear.'
  },
  {
    subHeading: 'Select the "Privacy and security" option.',
    subText: 'This option can be viewed on the left of the settings window.'
  },
  {
    subHeading: 'Click on the "Cookies and other site data" option.',
    subText: 'This will navigate us to a screen where we can configure the cookie settings.'
  },
  {
    subHeading: 'Select the "Allow all cookies" option.',
    subText: 'Then refresh the browser and login to the CDM application.'
  }],

  Firefox: [{
    subHeading: 'In your browser, click the menu button.',
    subText: 'In the top-right corner of the Firefox window you\'ll see the menu icon ',
    icon: 'fa fa-bars'
  },
  {
    subHeading: 'Click the "Options" or "Settings" menu item.',
    subText: 'This will open the Firefox settings menu.'
  },
  {
    subHeading: 'Select the "Privacy and security" option.',
    subText: 'This option can be viewed on the left of the settings window.'
  },
  {
    subHeading: 'Click on the "Customs" radio button option.',
    subText: 'Then check the "Cookies" option and select the "Cross-site tracking cookies" option '
    + 'from the dropdown displayed in front of the "Cookies" checkbox. Then refresh the browser and login to the CDM application.'
  }],

  Safari: [{
    subHeading: 'In your browser, click the "Safari" menu.',
    subText: 'Make sure you have a Safari window open and active.'
      + ' You will see the "Safari" menu at the top left of your screen.'
      + ' Click it and it will expand to show Safari-specific options.',
  },
  {
    subHeading: 'Click the "Preferences" menu item.',
    subText: 'Now that you have expanded the Safari menu,'
      + ' you will see the Preferences item. Click on it.'
  },
  {
    subHeading: 'Click the "Privacy" tab.',
    subText: 'Click the Privacy item to see privacy related options.'
  },
  {
    subHeading: 'Un-check Checkbox to prevent cross-site tracking.',
    subText: 'Also make sure the "Block all cookies" option is also unchecked'
      + ' then login to the CDM application.'
  }],

  Microsoft_Edge: [{
    subHeading: 'In Edge, click the "Settings and more" button.',
    subText: 'In the top right hand corner of your Edge browser window, '
      + 'You will see a small button with 3 dots on it.'
      + ' This is the "Settings and more" button.',
  },
  {
    subHeading: 'Select the "Settings" menu item.',
    subText: 'At the bottom of the drop down, you can see the settings option.'
      + ' Click on it.'
  },
  {
    subHeading: 'Find the "Manage and delete cookies and site data" section.',
    subText: 'Either you can search for the field "cookie" or you can select '
      + 'under the "Site permissions" section, click the "Cookies and site data"'
      + ' item to view cookie options.'
  },
  {
    subHeading: 'Disable the "Block third-party cookies" option.',
    subText: 'Unselect this option to enable third-party cookies '
    + 'then refresh the browser & login to the CDM application.'
  }],
};
