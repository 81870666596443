/**
 * Created by hgun0888
 * 12/3/18
 * */

/**
 * Action Types
 */
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';
export const RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT = 'RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT';
export const RECEIVE_ERROR_IN_VENDOR_ASSOCIATION_FILE_IMPORT = 'RECEIVE_ERROR_IN_VENDOR_ASSOCIATION_FILE_IMPORT';
export const RECEIVE_ERROR_IN_MASS_AGREEMENT_FILE_IMPORT = 'RECEIVE_ERROR_IN_MASS_AGREEMENT_FILE_IMPORT';
export const RECEIVE_ERROR_IN_VENDOR_FILE_PROCESS_STATUS_FETCH = 'RECEIVE_ERROR_IN_VENDOR_FILE_PROCESS_STATUS_FETCH';
export const RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH = 'RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH';
export const RECEIVE_ERROR_IN_832_FILES_FETCH = 'RECEIVE_ERROR_IN_832_FILES_FETCH';
export const RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH = 'RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH = 'RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_REPORT_TEMPLATE_CREATION = 'RECEIVE_ERROR_IN_REPORT_TEMPLATE_CREATION';
export const RECEIVE_ERROR_IN_REPORT_TEMPLATE_DELETION = 'RECEIVE_ERROR_IN_REPORT_TEMPLATE_DELETION';
export const RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH = 'RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_USER_GROUP_ASSOCIATION_DETAILS_FETCH = 'RECEIVE_ERROR_IN_USER_GROUP_ASSOCIATION_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER = 'RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER';
export const RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH = 'RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_USERS_FETCH = 'RECEIVE_ERROR_IN_USERS_FETCH';
export const RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH = 'RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH';
export const RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS = 'RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS';
export const RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH = 'RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH = 'RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH';
export const RECEIVE_ERROR_IN_VENDORS_FETCH = 'RECEIVE_ERROR_IN_VENDORS_FETCH';
export const RECEIVE_ERROR_IN_OPCO_FETCH = 'RECEIVE_ERROR_IN_OPCO_FETCH';
export const RECEIVE_ERROR_IN_REPORT_DATA_FETCH = 'RECEIVE_ERROR_IN_REPORT_DATA_FETCH';
export const RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH = 'RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH';
export const RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH = 'RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH';
export const RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION = 'RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION';
export const RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH = 'RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH';
export const RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH = 'RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH';
export const RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION = 'RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION';
export const RECEIVE_ERROR_IN_COST_DATA_FETCH = 'RECEIVE_ERROR_IN_COST_DATA_FETCH';
export const RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT = 'RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT';
export const RECEIVE_ERROR_IN_ITEMS_VALIDATION = 'RECEIVE_ERROR_IN_ITEMS_VALIDATION';
export const CLEAR_ITEMS_VALIDATION_ERROR = 'CLEAR_ITEMS_VALIDATION_ERROR';
export const RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE = 'RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE';
export const RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE = 'RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE';
export const RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION = 'RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION';
export const RECEIVE_FILE_LOADING_ERROR = 'RECEIVE_FILE_LOADING_ERROR';
export const RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH = 'RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH';
export const RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH = 'RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH';
export const RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH = 'RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH';
export const RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH = 'RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH';
export const RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS = 'RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS';
export const RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS = 'RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS';
export const RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS = 'RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS';
export const RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT = 'RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT';
export const RECEIVE_ERROR_UPDATE_SUPPLIER_CONTACT = 'ERROR_UPDATE_SUPPLIER_CONTACT';

/**
 * Actions
 */
export const hideErrorDialog = () => ({ type: HIDE_ERROR_DIALOG });
export const receiveErrorInVendorFileImport = (data) => (
  { type: RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT, data });
export const receiveErrorInVendorAssociationFileImport = (data) => (
  { type: RECEIVE_ERROR_IN_VENDOR_ASSOCIATION_FILE_IMPORT, data });
export const receiveErrorInMassAgreementFileImport = (data) => (
  { type: RECEIVE_ERROR_IN_MASS_AGREEMENT_FILE_IMPORT, data });
export const receiveErrorInVendorFileProcessStatusFetch = () => (
  { type: RECEIVE_ERROR_IN_VENDOR_FILE_PROCESS_STATUS_FETCH });
export const receiveErrorInVendorFileLogFetch = (data) => (
  { type: RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH, data });
export const receiveErrorInFileTransferDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH, data });
export const receiveErrorIn832FilesFetch = (data) => (
  { type: RECEIVE_ERROR_IN_832_FILES_FETCH, data });
export const receiveErrorInReportDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH, data });
export const receiveErrorInUserProfileDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH, data });
export const receiveErrorInUserGroupAssociationDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_USER_GROUP_ASSOCIATION_DETAILS_FETCH, data });
export const receiveErrorInUserProfileDetailsFetchOfSelectedUser = (data) => (
  { type: RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER, data });
export const receiveErrorInUserGroupDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH, data });
export const receiveErrorInUsersFetch = (data) => (
  { type: RECEIVE_ERROR_IN_USERS_FETCH, data });
export const receiveErrorInAllUserGroupsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH, data });
export const receiveErrorWhenSavingUserManagementDetails = (data) => (
  { type: RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS, data });
export const receiveErrorInSupplierOnboardDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH, data });
export const receiveErrorInUserAssociationAndBusinessCenterContactsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH, data });
export const receiveErrorInVendorsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_VENDORS_FETCH, data });
export const receiveErrorInOpcoFetch = (data) => (
  { type: RECEIVE_ERROR_IN_OPCO_FETCH, data });
export const receiveErrorInReportTemplateCreation = (data) => (
  { type: RECEIVE_ERROR_IN_REPORT_TEMPLATE_CREATION, data });
export const receiveErrorInReportTemplateDeletion = (data) => (
  { type: RECEIVE_ERROR_IN_REPORT_TEMPLATE_DELETION, data });
export const receiveErrorInBusinessCentersFetch = (data) => (
  { type: RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH, data });
export const receiveErrorInPriceRecordTypesFetch = (data) => (
  { type: RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH, data });
export const receiveErrorInSupplierSupportRequestSubmission = (data) => (
  { type: RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION, data });
export const receiveErrorInHelpTopicContentFetch = (data) => (
  { type: RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH, data });
export const receiveErrorInRequestDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH, data });
export const receiveErrorInWorkspaceRequestSubmission = (data) => (
  { type: RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION, data });
export const receiveErrorInCostDataFetch = (data) => (
  { type: RECEIVE_ERROR_IN_COST_DATA_FETCH, data });
export const receiveErrorInAdhocCostFileImport = (data) => (
  { type: RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT, data });
export const receiveErrorItemsValidation = (data) => (
  { type: RECEIVE_ERROR_IN_ITEMS_VALIDATION, data });
export const clearItemsValidationError = () => ({ type: CLEAR_ITEMS_VALIDATION_ERROR });
export const receiveErrorInRequestStatusUpdate = (data) => (
  { type: RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE, data });
export const receiveErrorInWorkspaceRequestActionChange = (data) => (
  { type: RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE, data });
export const receiveErrorInWorkspaceRequestCommentSubmission = (data) => (
  { type: RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION, data });
export const receiveFileLoadingError = (data) => (
  { type: RECEIVE_FILE_LOADING_ERROR, data });
export const receiveErrorInEnterpriseCostMasterDataFetch = (data) => (
  { type: RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH, data });
export const receiveErrorInEnterpriseCostDataFetch = (data) => (
  { type: RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH, data });
export const receiveErrorInEarnedIncomeMappingsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH, data });
export const receiveErrorInSASFDDetailsFetch = (data) => (
  { type: RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH, data });
export const receiveErrorWhenSavingVendorSASDetails = (data) => (
  { type: RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS, data });
export const receiveErrorInSASFDFileImport = (data) => (
  { type: RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT, data });
export const receiveErrorWhenAddingVendorSASDetails = (data) => (
  { type: RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS, data });
export const receiveErrorWhenSavingItemSasFdDetails = (data) => (
  { type: RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS, data });
export const receiveErrorUpdateSupplierContact = (data) => (
  { type: RECEIVE_ERROR_UPDATE_SUPPLIER_CONTACT, data });
