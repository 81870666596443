import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to reports
 *
 * @author hgun0888
 */

class ReportsProxy {
  static async fetchReportDetails(
    {
      username,
      reportName,
      reportType,
      startTime,
      endTime,
      page, pageSize, sorted, userGroups
    }
  ) {
    const sortOrder = sorted.desc ? 'DESC' : 'ASC';
    const url = `${URLS.REPORT_DETAILS_URL}`;
    const config = {
      url,
      params: {
        username,
        report_name: reportName,
        report_type: reportType,
        start_time: startTime,
        end_time: endTime,
        page,
        page_size: pageSize,
        sort_by: sorted.id,
        sort_order: sortOrder,
        groups: userGroups.join(',')
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchReportMetaData(type) {
    const config = {
      url: `${URLS.REPORT_METADATA_URL}`,
      params: {
        type
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchReportTemplateDetails(type, templateId) {
    const config = {
      url: `${URLS.REPORT_TEMPLATES_URL}`,
      params: {
        type,
        template_id: templateId
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async saveReportTemplate(data) {
    const config = {
      url: `${URLS.REPORT_TEMPLATES_URL}`,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async deleteReportTemplate(data) {
    const config = {
      url: `${URLS.REPORT_TEMPLATES_URL}/delete`,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }
}

export default ReportsProxy;
