/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * React Modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';
/**
 * Custom Modules
 */
import reportsApi from '../../externals/reportsProxy';
import {
  DELETE_REPORT_TEMPLATE,
  receiveReportMetaData,
  receiveReportsLog, receiveReportTemplateDeleteStatus,
  receiveReportTemplateDetails,
  receiveReportTemplatePersistStatus,
  REQUEST_REPORT_METADATA,
  REQUEST_REPORT_TEMPLATE_DETAILS,
  REQUEST_REPORTS_LOG,
  SAVE_REPORT_TEMPLATE,
} from '../actions/reportsActions';
import {
  receiveErrorInReportDetailsFetch,
  receiveErrorInReportTemplateCreation,
  receiveErrorInReportTemplateDeletion,
} from '../actions/errorDialogActions';
import {
  UNABLE_TO_DELETE_TEMPLATE,
  UNABLE_TO_LOAD_REPORT_DETAILS,
  UNABLE_TO_LOAD_REPORT_METADATA,
} from '../../helpers/messageConstant';
/**
 * Worker Saga: will be fired on Report related actions
 */
export function* fetchReportDetails({ data }) {
  try {
    const reports = yield call(reportsApi.fetchReportDetails, data);
    yield put(receiveReportsLog(reports));
  } catch (e) {
    yield put(receiveErrorInReportDetailsFetch(UNABLE_TO_LOAD_REPORT_DETAILS));
  }
}

export function* fetchReportMetaData({ data }) {
  try {
    const { type } = data;
    const metadata = yield call(reportsApi.fetchReportMetaData, type);
    yield put(receiveReportMetaData(metadata));
  } catch (e) {
    yield put(receiveErrorInReportDetailsFetch(UNABLE_TO_LOAD_REPORT_METADATA));
  }
}

export function* fetchReportTemplateDetails({ data }) {
  try {
    const { type, templateId } = data;
    const templateDetails = yield call(reportsApi.fetchReportTemplateDetails, type, templateId);
    yield put(receiveReportTemplateDetails(templateDetails));
  } catch (e) {
    yield put(receiveErrorInReportDetailsFetch(UNABLE_TO_LOAD_REPORT_DETAILS));
  }
}

export function* saveReportTemplate({ data }) {
  try {
    const metadata = yield call(reportsApi.saveReportTemplate, data);
    yield put(receiveReportTemplatePersistStatus(metadata));
  } catch (e) {
    yield put(receiveErrorInReportTemplateCreation(e.response.data));
  }
}

export function* deleteReportTemplate({ data }) {
  try {
    const metadata = yield call(reportsApi.deleteReportTemplate, data);
    yield put(receiveReportTemplateDeleteStatus(metadata));
  } catch (e) {
    yield put(receiveErrorInReportTemplateDeletion(UNABLE_TO_DELETE_TEMPLATE));
  }
}

/**
 * Starts reports related actions
 */
export function* reportsSaga() {
  yield takeEvery(REQUEST_REPORTS_LOG, fetchReportDetails);
  yield takeEvery(REQUEST_REPORT_METADATA, fetchReportMetaData);
  yield takeEvery(REQUEST_REPORT_TEMPLATE_DETAILS, fetchReportTemplateDetails);
  yield takeEvery(SAVE_REPORT_TEMPLATE, saveReportTemplate);
  yield takeEvery(DELETE_REPORT_TEMPLATE, deleteReportTemplate);
}
