import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to groups
 *
 * @author fris4874
 */
class UserGroupsProxy {
  static async createUserGroup(data) {
    const url = URLS.USER_GROUPS_URL;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };

    const response = await axios(config);
    return response.data;
  }

  static async getAllUserGroups() {
    const url = URLS.ALL_USER_GROUPS_URL;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };

    const response = await axios(config);
    return response.data;
  }

  static async getUserGroups(networkId) {
    const url = URLS.USER_GROUPS_URL;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        network_id: networkId
      }
    };

    const response = await axios(config);
    return response.data;
  }

  static async getUserGroupAssociationDetails(networkId) {
    const url = URLS.GET_USER_GROUP_ASSOCIATIONS_URL;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        network_id: networkId
      }
    };

    const response = await axios(config);
    return response.data;
  }
}

export default UserGroupsProxy;
