import axios from 'axios';
import BFFProxy from './bffProxy';
import axiosWithPayloadInterceptor from '../interceptor/axiosWithPayloadInterceptor';
import {
  URLS,
  HTTP_METHODS,
  FILE_TYPES,
  HTTP_STATUS
} from '../helpers/constants';
import { S3_FILE_UPLOAD_ERROR } from '../helpers/messageConstant';

/**
 * Fetch data from APIs related to agreements
 * @author skit1169
 */
class AgreementProxy {
  static async fetchAgreementSearchData(data) {
    const url = `${URLS.AGREEMENT_SEARCH_REQUEST_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchVendorBases() {
    const url = `${URLS.VENDOR_BASES_FETCH_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchBusinessCenters(data) {
    const url = `${URLS.BUSINESS_CENTERS_FETCH_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        vendor_id: data
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchSASFDDetails(data) {
    const url = `${URLS.SAS_FD_DETAILS_FETCH_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        vendor_id: data
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async updateSASValueDetails(data) {
    const config = {
      url: `${URLS.VENDOR_SAS_VALUE_UPDATE_URL}`,
      method: HTTP_METHODS.PUT,
      params: {
        applySAS: data.applySAS
      },
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async addSASValueDetails(data) {
    const config = {
      url: `${URLS.VENDOR_SAS_VALUE_ADD_URL}`,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async importSASFDFile(params) {
    const { file } = params;
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(FILE_TYPES.SAS_FD_REQUEST, file.name);
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        const data = {
          filePath: `${signedUrlResponse.data.path}/${signedUrlResponse.data.fileName}`,
          filename: encodeURIComponent(signedUrlResponse.data.fileName),
          actualFileName: file.name
        };
        const config = {
          url: `${URLS.SAS_FD_FILE_IMPORT_URL}`,
          method: HTTP_METHODS.POST,
          data
        };
        const response = await axiosWithPayloadInterceptor(config);
        return response.data;
      }
      return Promise.reject(new Error(S3_FILE_UPLOAD_ERROR));
    } catch (e) {
      return Promise.reject(new Error(e.message));
    }
  }

  static async updateSasFdDetails(data) {
    const config = {
      url: `${URLS.ITEM_SAS_FD_DETAILS_UPDATE_URL}`,
      method: HTTP_METHODS.PUT,
      data
    };
    const response = await axios(config);
    return response.data;
  }
}

export default AgreementProxy;
