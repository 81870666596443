import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { version } from '../../../package.json';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Footer extends Component {
  render = () => (
    <React.Fragment>
      <span>Cost Data Management - Version {version} </span>
      <span className="ml-auto" style={{ left: '50%' }}>All Rights Reserved By <a href="https://sysco.com">Sysco</a> &copy; 2024</span>
    </React.Fragment>
  )
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
