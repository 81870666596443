/**
 * Created by ysum5308
 * */
import { COST_RECORD_STATUS, COSTING_ERROR } from '../../helpers/constants';
import initialState from './initialState';
import { isEmptyObject, isCostMaintenanceRequest } from '../../util/validator';
import {
  RECEIVE_REQUEST_DETAILS,
  RESET_SELECTED_REQUEST_DETAILS,
  RECEIVE_REQUEST_WITH_VARIATION_DETAILS,
} from '../actions/workspaceActions';

function updateCostMaintenanceReports(data, costImportRecord, costImportRecordsMap,
  reportGeneratedStatusMap, exceptionReportList, notImportedReportList, variationReportList) {
  const { lineItemId } = costImportRecord;

  costImportRecordsMap[costImportRecord.lineItemId] = { ...costImportRecord };

  // Sorts the exception, not-imported and variation reports by rowId
  if (reportGeneratedStatusMap.isExceptionReport && data.exceptionReport.records[lineItemId]) {
    exceptionReportList.push(data.exceptionReport.records[lineItemId]);
  }

  if (reportGeneratedStatusMap.isNotImportedReport && data.notImportedReport.records[lineItemId]) {
    notImportedReportList.push(data.notImportedReport.records[lineItemId]);
  }

  if (reportGeneratedStatusMap.isVariationReport && data.variationReport.records[lineItemId]) {
    variationReportList.push(data.variationReport.records[lineItemId]);
  }
}

function updateCostImportRecord(costImportRecord, outputCostRecordsMap) {
  const { lineItemId } = costImportRecord;
  const outputCostRecord = outputCostRecordsMap[lineItemId];
  if (outputCostRecord) {
    costImportRecord.suggestedSell = outputCostRecord.suggestedSell;
    costImportRecord.squeezedAmount = outputCostRecord.squeezedAmount;
  }
}

function isReportGenerated(report) {
  return report && report.generated;
}

function populateCostMaintenanceReports(data, costImportRecordsMap, exceptionReportList,
  notImportedReportList, variationReportList, outputCostRecordsMap) {
  const reportGeneratedStatusMap = {
    isExceptionReport: isReportGenerated(data.exceptionReport),
    isNotImportedReport: isReportGenerated(data.notImportedReport),
    isVariationReport: isReportGenerated(data.variationReport)
  };

  if (data.outputCostRecords) {
    data.outputCostRecords.forEach((outputCostRecord) => {
      outputCostRecordsMap[outputCostRecord.lineItemId] = { ...outputCostRecord };
    });
  }

  if (data.costImportRecords) {
    data.costImportRecords.forEach((costImportRecord) => {
      updateCostImportRecord(costImportRecord, outputCostRecordsMap);
      updateCostMaintenanceReports(data, costImportRecord, costImportRecordsMap,
        reportGeneratedStatusMap, exceptionReportList, notImportedReportList, variationReportList);
    });
  }
}

function populateCostMaintenanceReportsOfParent(subRequest, costImportRecords, exceptionReportList,
  notImportedReportList, outputCostRecordsMap) {
  if (subRequest.costImportRecords) {
    subRequest.costImportRecords.forEach((record) => {
      const rowId = costImportRecords.length + 1;
      const { lineItemId } = record;

      // Aggregate cost import records of all children at the parent-level
      const costImportRecord = { ...record, rowId };
      costImportRecords.push(costImportRecord);

      const isExceptionReport = isReportGenerated(subRequest.exceptionReport);
      const isNotImportedReport = isReportGenerated(subRequest.notImportedReport);

      // Aggregate all child reports at the parent-level
      if (isExceptionReport && subRequest.exceptionReport.records[lineItemId]) {
        const exceptionRecord = { ...subRequest.exceptionReport.records[lineItemId], rowId };
        exceptionReportList.push(exceptionRecord);
      }

      if (isNotImportedReport && subRequest.notImportedReport.records[lineItemId]) {
        const notImportedRecord = { ...subRequest.notImportedReport.records[lineItemId], rowId };
        notImportedReportList.push(notImportedRecord);
      }
    });
  }

  if (subRequest.outputCostRecords) {
    subRequest.outputCostRecords.forEach((outputCostRecord) => {
      outputCostRecordsMap[outputCostRecord.lineItemId] = { ...outputCostRecord };
    });
  }
}

function createSortedCostMaintenanceReports(data) {
  if (isCostMaintenanceRequest(data.requestType)) {
    const costImportRecordsMap = {};
    const outputCostRecordsMap = {};
    const exceptionReportList = [];
    const notImportedReportList = [];
    const variationReportList = [];

    // if child requests
    if (isEmptyObject(data.subRequests)) {
      populateCostMaintenanceReports(data, costImportRecordsMap, exceptionReportList,
        notImportedReportList, variationReportList, outputCostRecordsMap);
    } else {
      const { subRequests } = data;
      const costImportRecords = [];
      Object.values(subRequests).forEach((subRequest) => {
        populateCostMaintenanceReportsOfParent(subRequest, costImportRecords, exceptionReportList,
          notImportedReportList, outputCostRecordsMap);
      });
      data.costImportRecords = costImportRecords;
    }

    data.costImportRecordsMap = costImportRecordsMap;
    data.exceptionReportList = exceptionReportList;
    data.notImportedReportList = notImportedReportList;
    data.variationReportList = variationReportList;
    data.outputCostRecordsMap = outputCostRecordsMap;
  }
}

function formatCostMaintenanceReportsAfterVariation(costRecord, data, lineItemId, mergedExceptionReportMap,
  mergedNotImportedReportMap, mergedVariationReportMap) {
  if (data.variationReport && data.variationReport.generated === true && data.variationReport.records[lineItemId]) {
    const variationRecord = data.variationReport.records[lineItemId];
    costRecord.displayMode = variationRecord.displayMode;
    costRecord.weightIndicator = variationRecord.weightInd;
    costRecord.grossWeight = variationRecord.grossWeight;
    costRecord.currentCalculatedCost = variationRecord.oldBsccSpcCost;
    costRecord.calculatedCost = variationRecord.newBsccSpcCost;
    costRecord.calculatedCostDiff = variationRecord.pctChangeBsccSpcCost;
  }
  if (mergedExceptionReportMap[lineItemId]
    && costRecord.status !== COST_RECORD_STATUS.DECLINED
    && costRecord.message !== COSTING_ERROR.COST_FORCED_DOWN) {
    delete mergedExceptionReportMap[lineItemId];
  }
  if (mergedNotImportedReportMap[lineItemId] && costRecord.status !== COST_RECORD_STATUS.DECLINED) {
    delete mergedNotImportedReportMap[lineItemId];
  }
  if (mergedVariationReportMap[lineItemId] && costRecord.status === COST_RECORD_STATUS.DECLINED) {
    delete mergedVariationReportMap[lineItemId];
  }
}

function createSortedCostMaintenanceReportsAfterVariation(data, costImportRecord, mergedExceptionReportMap,
  mergedNotImportedReportMap, mergedVariationReportMap, mergedOutputCostRecordsMap, listMap) {
  let costRecord = { ...costImportRecord };
  const { lineItemId } = costRecord;
  if (data.costImportRecordsMap[lineItemId]) {
    costRecord = { ...data.costImportRecordsMap[lineItemId] };
    formatCostMaintenanceReportsAfterVariation(costRecord, data, lineItemId, mergedExceptionReportMap,
      mergedNotImportedReportMap, mergedVariationReportMap);
  }
  updateCostImportRecord(costRecord, mergedOutputCostRecordsMap);

  costRecord.isEdited = false;
  costRecord.rejected = costRecord.status === COST_RECORD_STATUS.DECLINED ? true : data.rowIdMap[lineItemId].rejected;
  costRecord.comment = data.rowIdMap[lineItemId]?.comment ? data.rowIdMap[lineItemId].comment : '';
  listMap.costImportRecordList.push(costRecord);
  if (mergedExceptionReportMap[lineItemId]) {
    listMap.exceptionReportList.push(mergedExceptionReportMap[lineItemId]);
  }
  if (mergedNotImportedReportMap[lineItemId]) {
    listMap.notImportedReportList.push(mergedNotImportedReportMap[lineItemId]);
  }
  if (mergedVariationReportMap[lineItemId]) {
    listMap.variationReportList.push(mergedVariationReportMap[lineItemId]);
  }
}

function createOutputCostRecordListAfterVariation(costImportRecord, mergedOutputCostRecordsMap, outputCostRecordList) {
  const { lineItemId } = costImportRecord;
  if (mergedOutputCostRecordsMap[lineItemId]) {
    outputCostRecordList.push(mergedOutputCostRecordsMap[lineItemId]);
  }
}

function updateCostMaintenanceReportsAfterVariation(state, data) {
  const listMap = {
    costImportRecordList: [],
    exceptionReportList: [],
    notImportedReportList: [],
    variationReportList: [],
  };
  const exceptionReport = data.exceptionReport.generated === true ? data.exceptionReport.records : {};
  const mergedExceptionReportMap = { ...state.exceptionReport?.records, ...exceptionReport };
  const notImportedRecords = data.notImportedReport.generated === true ? data.notImportedReport.records : {};
  const mergedNotImportedReportMap = { ...state.notImportedReport?.records, ...notImportedRecords };

  const variationReport = data.variationReport.generated === true ? data.variationReport.records : {};
  const mergedVariationReportMap = { ...state.variationReport?.records, ...variationReport };

  const mergedOutputCostRecordsMap = { ...state.outputCostRecordsMap, ...data.outputCostRecordsMap };
  const outputCostRecordList = [];

  state.costImportRecords.forEach((costImportRecord) => {
    createSortedCostMaintenanceReportsAfterVariation(data, costImportRecord, mergedExceptionReportMap,
      mergedNotImportedReportMap, mergedVariationReportMap, mergedOutputCostRecordsMap, listMap);
    createOutputCostRecordListAfterVariation(costImportRecord, mergedOutputCostRecordsMap, outputCostRecordList);
  });

  data.exceptionReport = { generated: listMap.exceptionReportList.length > 0, records: mergedExceptionReportMap };
  data.notImportedReport = { generated: listMap.notImportedReportList.length > 0, records: mergedNotImportedReportMap };
  data.variationReport = { generated: listMap.variationReportList.length > 0, records: mergedVariationReportMap };
  data.outputCostRecordsMap = mergedOutputCostRecordsMap;
  data.costImportRecords = listMap.costImportRecordList;
  data.exceptionReportList = listMap.exceptionReportList;
  data.notImportedReportList = listMap.notImportedReportList;
  data.variationReportList = listMap.variationReportList;
  data.costImportRecordsMap = { ...state.costImportRecordsMap, ...data.costImportRecordsMap };
  data.outputCostRecords = outputCostRecordList;
}

export default function requestDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.workspace.selectedRequest;
  }

  if (type === RECEIVE_REQUEST_DETAILS) {
    createSortedCostMaintenanceReports(data);
    return { ...data };
  }

  if (type === RECEIVE_REQUEST_WITH_VARIATION_DETAILS) {
    updateCostMaintenanceReportsAfterVariation(state, data);
    return { ...state, ...data };
  }

  if (type === RESET_SELECTED_REQUEST_DETAILS) {
    return initialState.workspace.selectedRequest;
  }

  return state;
}
