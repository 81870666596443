/**
 * Created by fris4874
 * */
import initialState from './initialState';
import { RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT, CLEAR_VENDOR_ASSOCIATION_FILES_LIST } from '../actions/filesActions';

export default function vendorAssociationFileImportReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.userManagement;
  }

  if (type === RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT) {
    if (data) {
      return { ...state, vendorAssociationImportFiles: [...data, ...state.vendorAssociationImportFiles] };
    }
  }

  if (type === CLEAR_VENDOR_ASSOCIATION_FILES_LIST) {
    return initialState.userManagement;
  }

  return state;
}
