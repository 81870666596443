import React, { Component } from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Nav
} from 'reactstrap';
import PropTypes from 'prop-types';
import { AppHeaderDropdown, AppSidebarToggler } from '@coreui/react';
import { withRouter } from 'react-router-dom';
import syscofulllogo from '../../assets/images/logo/sysco-logo-header.png';
import { logout } from '../../service/userService';
import UserGroupDropDown from '../userGroupDropDown/userGroupDropDown';
import { store } from '../../internals/store';
import { USER_TYPES } from '../../helpers/constants';

const defaultProps = {};

const emailStyle = {
  marginRight: '10px'
};

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserDropdownOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isUserDropdownOpen: !this.state.isUserDropdownOpen
    });
  };

  render() {
    const { userType } = store.getState().userReducer;
    const { isEnterpriseCostUser } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile/>
        <img src={syscofulllogo} alt='Sysco Logo' width={100} height={50} style={{ marginLeft: '10px' }}/>

        <Nav className="ml-auto" navbar>
          {userType === USER_TYPES.INTERNAL && !isEnterpriseCostUser && <UserGroupDropDown />}
          <AppHeaderDropdown direction="down" style={{ marginLeft: '10px' }} isOpen={this.state.isUserDropdownOpen} toggle={this.toggle}>
            <DropdownToggle nav id={'app_header_current_user_email_toggle'} className='username' aria-haspopup={true} aria-expanded={false}>
              <div id={'app_header_current_user_email_div'} style={emailStyle}>{this.props.user.email} { this.state.isUserDropdownOpen
                ? <i className="fa fa-angle-up" style={{ padding: '8px' }}/>
                : <i className="fa fa-angle-down" style={{ padding: '8px' }}/>
              }</div>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto', width: '100%', borderRadius: '0' }} >
              {!isEnterpriseCostUser
              && <DropdownItem id={'app_header_user_profile_dropdown_item'} onClick={this.profile}><i className="fa fa-user-circle-o"/> My Profile</DropdownItem>}
              <DropdownItem id={'app_header_logout_dropdown_item'} onClick={logout}><i className="fa fa-lock"/> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }

  profile = () => {
    this.props.history.push('/user/profile');
  };
}

Header.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  isEnterpriseCostUser: PropTypes.bool,
};
Header.defaultProps = defaultProps;

export default withRouter(Header);
