import React, { Component } from 'react';
import { RingLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import CookiePolicy from '../Cookies/cookiePolicy';
import { IDENTITY_PROVIDER, URLS, EXTERNAL_APPS_URLS } from '../../helpers/constants';
import logo from '../../assets/images/logo/sysco-logo-half-blue.png';
import '../../scss/landing-page.scss';
import { store } from '../../internals/store';

/**
 * Landing page
 *
 * @author hgun0888
 */
const loaderCSS = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const ORIGIN_PARAMETER = 'origin';
const USER_TYPE_PARAMETER = 'user_type';

const SDP_ORIGIN = 'sdp';
const USER_TYPE_VENDOR = 'supplier';
const USER_TYPE_ASSOCIATE = 'associate';

export class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookiePolicyModel: false,
    };
  }

  hideCookiePolicyModel = () => {
    this.setState({ showCookiePolicyModel: false });
  }

  componentDidMount() {
    const isAllCookieEnabled = store.getState().cookieReducer.isCookie;
    if ((isAllCookieEnabled !== undefined && !isAllCookieEnabled) || !navigator.cookieEnabled) {
      this.setState({ showCookiePolicyModel: true });
    }
  }

  login = (userType) => {
    if (navigator.cookieEnabled) {
      if (userType === USER_TYPE_ASSOCIATE) {
        window.location.assign(`${URLS.LOGIN_URL}?identity_provider=${IDENTITY_PROVIDER.AZURE_AD}&redirect_url=${window.location.origin}/login_callback`);
      } else if (userType === USER_TYPE_VENDOR) {
        window.location.assign(`${URLS.LOGIN_URL}?identity_provider=${IDENTITY_PROVIDER.OKTA}&redirect_url=${window.location.origin}/login_callback`);
      }
    } else {
      this.setState({ showCookiePolicyModel: true });
    }
  }

  render = () => {
    const params = this.props.location && this.props.location.search ? new URLSearchParams(this.props.location.search) : null;
    if (params && params.get(ORIGIN_PARAMETER) === SDP_ORIGIN) {
      if (params.get(USER_TYPE_PARAMETER) === USER_TYPE_VENDOR) {
        window.location.assign(`${URLS.LOGIN_URL}?identity_provider=${IDENTITY_PROVIDER.OKTA}&redirect_url=${window.location.origin}/login_callback`);
      } else if (params.get(USER_TYPE_PARAMETER) === USER_TYPE_ASSOCIATE) {
        window.location.assign(`${URLS.LOGIN_URL}?identity_provider=${IDENTITY_PROVIDER.AZURE_AD}&redirect_url=${window.location.origin}/login_callback`);
      }

      if (((params.get(USER_TYPE_PARAMETER) === USER_TYPE_ASSOCIATE) || (params.get(USER_TYPE_PARAMETER) === USER_TYPE_VENDOR))) {
        return (<div className="app flex-row align-items-center"><Container>
          <Row className="justify-content-center">
            <p>Redirecting...</p>
          </Row>
          <Row className="justify-content-center">
            <div className='sweet-loading' id={'login_loader_div'}>
              <RingLoader
                className={loaderCSS}
                sizeUnit={'px'}
                size={80}
                color={'#123abc'}
                loading={true}
              />
            </div>
          </Row>
        </Container>
        </div>);
      }
    }
    return (
      <React.Fragment>
        <CookiePolicy
          id='cookie-policy-landing-page'
          isOpen={this.state.showCookiePolicyModel}
          hideModal={this.hideCookiePolicyModel}
        />
        <div className="wrapper login-wrapper">
          <div className="login-panel user-selection">
            <img src={logo} alt="Sysco Cost Data Management" className="logo" />
            <div className="logoname">Cost Data Management</div>
            <div className="title">Welcome</div>
            <div>Please select what type of user you are</div>
            <ul className="selection">
              <li id="vendor_login_link" onClick={() => this.login(USER_TYPE_VENDOR)} >
                <div className="icon vendor fi flaticon-supplier" />
                <div className="statement">I am a</div>
                <div className="type">Supplier</div>
              </li>
              <li id="sysco_associate_login_link"
                onClick={() => this.login(USER_TYPE_ASSOCIATE)}>
                <div className="icon associate fi flaticon-associate" />
                <div className="statement">I am a</div>
                <div className="type">Sysco Associate</div>
              </li>
            </ul>
            <div id="vendor_forgot_password_link"
              className="forgot-password"
              onClick={() => window.open(EXTERNAL_APPS_URLS.OKTA_PASSWORD_RESET_URL, '_blank', 'noopener,noreferrer')}>
              Forgot password?
            </div>
          </div>
        </div>
      </React.Fragment>);
  }
}

LandingPage.propTypes = {
  location: PropTypes.any
};
export default LandingPage;
