import {
  call, put, takeEvery
} from 'redux-saga/effects';
import enterpriseCostApi from '../../externals/enterpriseCostProxy';
import masterDataApi from '../../externals/masterDataProxy';
import { receiveErrorInEnterpriseCostMasterDataFetch, receiveErrorInEnterpriseCostDataFetch } from '../actions/errorDialogActions';
import { UNABLE_TO_LOAD_ENTERPRISE_COST_MASTER_DATA, UNABLE_TO_LOAD_ENTERPRISE_COST_DATA } from '../../helpers/messageConstant';
import {
  REQUEST_ENTERPRISE_COST_MASTER_DATA,
  REQUEST_ENTERPRISE_COST_DATA,
  receiveEnterpriseCostMasterData,
  receiveEnterpriseCostData,
} from '../actions/enterpriseCostActions';

/**
 * Worker Saga: will be fired on Enterprise cost related actions
 * @author skit1169
 */

export function* requestEnterpriseCostMasterData() {
  try {
    const response = yield call(masterDataApi.fetchSites);
    yield put(receiveEnterpriseCostMasterData(response));
  } catch (error) {
    yield put(receiveErrorInEnterpriseCostMasterDataFetch(UNABLE_TO_LOAD_ENTERPRISE_COST_MASTER_DATA));
  }
}

export function* requestEnterpriseCostData({ data }) {
  try {
    const response = yield call(enterpriseCostApi.fetchEnterpriseCostData, data);
    yield put(receiveEnterpriseCostData({ ...response, houseName: data.houseName }));
  } catch (e) {
    yield put(receiveErrorInEnterpriseCostDataFetch(UNABLE_TO_LOAD_ENTERPRISE_COST_DATA));
  }
}

export function* enterpriseCostSaga() {
  yield takeEvery(REQUEST_ENTERPRISE_COST_MASTER_DATA, requestEnterpriseCostMasterData);
  yield takeEvery(REQUEST_ENTERPRISE_COST_DATA, requestEnterpriseCostData);
}
