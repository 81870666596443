/**
 * Created by ysum5308
 * */
import initialState from './initialState';
import {
  REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES,
  RECEIVE_SUPPLIER_SUPPORT_REQUEST_TYPES,
  RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_TYPES_FETCH,
} from '../actions/workspaceActions';

export default function contactUsReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.workspace.contactUs;
  }

  if (type === REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES) {
    return { ...state, loading: true, error: { show: false, message: '' } };
  }

  if (type === RECEIVE_SUPPLIER_SUPPORT_REQUEST_TYPES) {
    return { ...state, supportRequestTypes: data.issueTypes, loading: false };
  }

  if (type === RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_TYPES_FETCH) {
    return { ...state, loading: false, error: { show: true, message: data } };
  }

  return state;
}
