import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to users
 *
 * @author hgun0888
 */

class UsersProxy {
  static async getUsers(data) {
    const types = data.join(',');
    const url = URLS.USERS_URL;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        types
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchLoggedInUserDetails() {
    const config = {
      url: `${URLS.USER_DETAILS_URL}`,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchDatadogSecrets() {
    const config = {
      url: `${URLS.DATADOG_SECRETS_URL}`,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchUserProfileDetails(networkId) {
    let config;
    if (!networkId) {
      config = {
        url: `${URLS.MY_PROFILE_DETAILS_URL}`,
        method: HTTP_METHODS.GET
      };
    } else {
      config = {
        url: `${URLS.USER_PROFILE_DETAILS_URL}`,
        method: HTTP_METHODS.GET,
        params: {
          network_id: networkId
        }
      };
    }
    const response = await axios(config);
    return response.data;
  }

  static async fetchUserProfileManagementDetails(networkId) {
    const config = {
      url: `${URLS.USER_PROFILE_MANAGEMENT_DETAILS_URL}`,
      method: HTTP_METHODS.GET,
      params: {
        network_id: networkId
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchUserAssociationAndBusinessCenterContacts(suvc) {
    const config = {
      url: `${URLS.USER_ASSOCIATION_AND_BUSINESS_CENTERS_CONTACTS_URL}`,
      method: HTTP_METHODS.GET,
      params: {
        suvc
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchSupplierOnboardDetails(userId) {
    const config = {
      url: `${URLS.SUPPLIER_ONBOARD_DETAILS_URL}`,
      method: HTTP_METHODS.GET,
      params: {
        user_id: userId
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async updateSupervisor(data) {
    const config = {
      url: `${URLS.USER_SUPERVISOR_ASSOCIATION_URL}`,
      method: HTTP_METHODS.PUT,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async updateVendorAssociations(data) {
    const config = {
      url: `${URLS.USER_VENDORS_ASSOCIATION_URL}`,
      method: HTTP_METHODS.PUT,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async updateGroupAssociations(data) {
    const config = {
      url: `${URLS.USER_GROUPS_URL}`,
      method: HTTP_METHODS.PUT,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async createSupplier(data) {
    const url = URLS.SUPPLIER_PROFILE_DETAILS_URL;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response;
  }

  static async updateSupplier(data) {
    const url = URLS.SUPPLIER_PROFILE_DETAILS_URL;
    const config = {
      url,
      method: HTTP_METHODS.PUT,
      data
    };

    const response = await axios(config);
    return response.data;
  }

  static async updateSupplierContact(data) {
    const url = URLS.UPDATE_SUPPLIER_CONTACTS_URL;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }
}

export default UsersProxy;
