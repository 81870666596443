/**
 * Created by hgun0888
 * */

/**
 * React Modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';
/**
 * Custom Modules
 */
import vendorsApi from '../../externals/vendorProxy';
import { receiveErrorInVendorsFetch, receiveErrorInOpcoFetch } from '../actions/errorDialogActions';
import { UNABLE_TO_LOAD_VENDORS, UNABLE_TO_LOAD_OPCOS } from '../../helpers/messageConstant';
import {
  receiveAllVendors,
  receiveAllOpcos,
  REQUEST_ALL_VENDORS,
  REQUEST_ALL_OPCOS
} from '../actions/vendorActions';
/**
 * Worker Saga: will be fired on Vendor related actions
 */

export function* fetchAllVendors() {
  try {
    const vendors = yield call(vendorsApi.fetchAllVendors);
    yield put(receiveAllVendors(vendors));
  } catch (e) {
    yield put(receiveErrorInVendorsFetch(UNABLE_TO_LOAD_VENDORS));
  }
}

export function* fetchAllOpcos() {
  try {
    const vendors = yield call(vendorsApi.fetchAllOpcos);
    yield put(receiveAllOpcos(vendors));
  } catch (e) {
    yield put(receiveErrorInOpcoFetch(UNABLE_TO_LOAD_OPCOS));
  }
}

/**
 * Starts vendor related actions
 */
export function* vendorSaga() {
  yield takeEvery(REQUEST_ALL_VENDORS, fetchAllVendors);
  yield takeEvery(REQUEST_ALL_OPCOS, fetchAllOpcos);
}
