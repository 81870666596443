/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * React Modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';
/**
 * Custom Modules
 */
import api from '../../externals/costMaintenanceProxy';
import { receiveFileTransferDetails, REQUEST_FILE_TRANSFER_DETAILS } from '../actions/fileTransferDetailsActions';
import { receiveErrorInFileTransferDetailsFetch } from '../actions/errorDialogActions';
import { UNABLE_TO_LOAD_FILE_TRANSFER_DETAILS } from '../../helpers/messageConstant';

/**
 * Worker Saga: will be fired on REQUEST_832_FILES actions
 */
export function* fetchFileTransferDetails({ data }) {
  try {
    const files = yield call(api.fetchFileTransferDetails, data.serverType, data.userGroups);
    yield put(receiveFileTransferDetails({ ...files, serverType: data.serverType }));
  } catch (e) {
    yield put(receiveErrorInFileTransferDetailsFetch(UNABLE_TO_LOAD_FILE_TRANSFER_DETAILS));
  }
}

/**
 * Starts fetch832Files on each dispatched `REQUEST_832_FILES` action.
 Allows concurrent fetches of 832 files.
 */
export function* fetchFileTransferDetailsSaga() {
  yield takeEvery(REQUEST_FILE_TRANSFER_DETAILS, fetchFileTransferDetails);
}
