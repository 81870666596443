/**
 * Created by aheshperera
 * 12/3/18
 * */

import initialState from './initialState';
import { RECEIVE_FILE_TRANSFER_DETAILS } from '../actions/fileTransferDetailsActions';

function getFormattedFileTransferDetails(files) {
  let importCount = 0;
  let globalChangeCount = 0;
  let opcoChangeCount = 0;
  let successCount = 0;
  let failedCount = 0;

  const fileTransferList = [];
  files?.data.forEach((fileDetail) => {
    if (fileDetail.fileCategory) {
      const fileCategory = String(fileDetail.fileCategory);
      if (fileCategory.toUpperCase()
        .includes('GLOBAL')) {
        globalChangeCount += 1;
      } else if (fileCategory.toUpperCase()
        .includes('OPCO')) {
        opcoChangeCount += 1;
      } else if (fileCategory.toUpperCase()
        .includes('IMPORT')) {
        importCount += 1;
      }
    }
    if (fileDetail.fileTransferStatus && fileDetail.fileTransferStatus.toUpperCase() === 'SENT') {
      successCount += 1;
    } else {
      failedCount += 1;
    }

    fileTransferList.push({
      businessProcessId: fileDetail.businessProcessId,
      pcpFileName: fileDetail.pcpFileName,
      fileCategory: fileDetail.fileCategory,
      fileSentBy: fileDetail.fileSentBy,
      fileSentTime: fileDetail.fileSentTime,
      rmeFileName: fileDetail.rmeFileName,
      fileTransferStatus: fileDetail.fileTransferStatus,
      suvc: fileDetail.suvc,
      vendor: fileDetail.vendor,
      importFileName: fileDetail.importFileName,
      businessCenter: fileDetail.businessCenter,
      noOfExceptionRecords: fileDetail.noOfExceptionRecords,
      noOfValidRecords: fileDetail.noOfValidRecords
    });
  });
  return {
    transferSummaryDetails: {
      importCount,
      globalChangeCount,
      opcoChangeCount,
      successCount,
      failedCount,
    },
    fileTransferList,
    serverType: files.serverType,
  };
}

export default function fileTransferDetailsReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.fileTransferDetails;
  }

  if (type === RECEIVE_FILE_TRANSFER_DETAILS) {
    const fileDetails = getFormattedFileTransferDetails(data);
    if (data.serverType === 'P1') {
      return {
        ...state,
        P1: {
          fileTransferList: fileDetails.fileTransferList,
          transferSummaryDetails: fileDetails.transferSummaryDetails
        }
      };
    }

    if (data.serverType === 'P2') {
      return {
        ...state,
        P2: {
          fileTransferList: fileDetails.fileTransferList,
          transferSummaryDetails: fileDetails.transferSummaryDetails
        }
      };
    }
  }
  return state;
}
