import initialState from './initialState';
import {
  FETCH_DATADOG_SECRETS,
  FETCH_DATADOG_SECRETS_ERROR,
} from '../actions/userManagementActions';
/**
 *  Secrets data
 */

export default function secretsReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.secrets;
  }

  if (type === FETCH_DATADOG_SECRETS) {
    return { ...state, datadogSecrets: data };
  }

  if (type === FETCH_DATADOG_SECRETS_ERROR) {
    return { ...state, error: data };
  }

  return state;
}
