import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MultiSelectDropDown extends Component {
  constructor(props) {
    super(props);
    let disabled = false;
    let headerTitle = props.title;
    if (props.list === undefined || props.list.length === 0) {
      disabled = true;
      headerTitle = props.disabledTitle;
    }
    this.state = {
      listOpen: false,
      disabledTitle: props.disabledTitle,
      defaultTitle: props.title,
      headerTitle,
      timeOut: null,
      list: props.list,
      activeSelected: [],
      selectedItems: [],
      searchedItems: props.list,
      disableClose: false,
      disabled
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    // Add auto increment unique id for item reference
    const { list } = this.state;
    let activeSelected = [];

    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        list[i].id = i;
        if (list[i].selected === undefined) {
          list[i].selected = false;
        } else if (list[i].selected) {
          activeSelected = [...activeSelected, list[i]];
        }
      }
    }

    this.setState({
      list,
      headerTitle: this.generateHeader(list, activeSelected),
      selectedItems: activeSelected,
      activeSelected
    });
  }

  componentDidUpdate(prevProps) {
    const { listOpen } = this.state;
    if (prevProps.list !== this.props.list) {
      const selectedItems = this.state.selectedItems.map(({ description }) => description);
      const updatedItems = this.props.list.map((item, index) => {
        item.id = index;
        item.selected = selectedItems.includes(item.description);
        return item;
      });
      this.setState({
        list: [...updatedItems],
        searchedItems: [...updatedItems],
      });
    }
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener('click', this.handleClose);
      } else {
        window.removeEventListener('click', this.handleClose);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClose);
  }

  handleClose() {
    if (!this.state.disableClose) {
      const { activeSelected, list } = this.state;
      // Generate flat map of ids for active selected itmes
      const activeSelectedIds = activeSelected.map((item) => item.id);
      // Generate header for active selected items
      const header = this.generateHeader(list, activeSelected);
      // Map dropdown selected items with actively selected items
      list.map((item) => item).forEach((item) => {
        const tempItem = item;
        tempItem.selected = activeSelectedIds.includes(item.id);
        return tempItem;
      });
      this.setState({
        listOpen: false,
        searchedItems: this.state.list,
        headerTitle: header,
        selectedItems: activeSelected
      });
    } else {
      this.setState({
        disableClose: false
      });
    }
  }

  select() {
    const { onSubmit } = this.props;
    const { selectedItems } = this.state;
    // Submit selected items to the outer scope
    onSubmit(selectedItems);
    this.setState({
      activeSelected: selectedItems
    });
  }

  search = (event) => {
    const searchText = event.target.value;
    let { list } = this.state;
    if (searchText.length !== 0) {
      list = list.filter((item) => item.description.toLowerCase().search(
        event.target.value.toLowerCase()
      ) !== -1);
    }
    this.setState({
      searchedItems: list,
    });
  };

  searchClick() {
    this.setState({
      disableClose: true
    });
  }

  reset() {
    let { list } = this.state;
    list = list.map((item) => {
      const tempItem = item;
      tempItem.selected = false;
      return tempItem;
    });
    this.setState({
      headerTitle: this.state.defaultTitle,
      list,
      selectedItems: [],
      listOpen: true,
      searchedItems: list,
      activeSelected: []
    });
    const { onSubmit } = this.props;
    onSubmit([]);
  }

  generateHeader(list, activeSelected) {
    if (list === undefined || list.length === 0) {
      return this.state.disabledTitle;
    }
    const count = activeSelected.length;
    if (count === 0) {
      return this.state.defaultTitle;
    }
    if (count === 1) {
      return `${activeSelected[0].description}`;
    }
    if (count === 2) {
      return `${activeSelected[0].description}, ${activeSelected[1].description}`;
    }
    if (count > 2) {
      return `${activeSelected[0].description}, ${activeSelected[1].description} +${count - 2}`;
    }
    return this.state.defaultTitle;
  }

  toggleSelected(itemDescription) {
    const { list } = this.state;

    // Get Id of selected item
    const { id } = list.find((item) => {
      if (item.description === itemDescription) {
        item.selected = !item.selected;
        return item;
      }
      return null;
    });

    // Populate selected item list
    let { selectedItems } = this.state;
    if (this.state.list[id].selected) {
      selectedItems = [...selectedItems, this.state.list[id]];
    } else {
      selectedItems = selectedItems.filter((a) => a.id !== id);
    }
    // Generate dropdown header
    const headerTitle = this.generateHeader(list, selectedItems);
    this.setState({
      headerTitle,
      selectedItems
    });
  }

  toggleList(disabled) {
    if (!disabled) {
      this.setState((prevState) => ({
        listOpen: !prevState.listOpen
      }));
    }
  }

  render() {
    const { listOpen, headerTitle, searchedItems } = this.state;
    const { error } = this.props;
    return (
        <div className="dd-wrapper" id={this.props.id}>
          <div className="dd-header" onClick={() => this.toggleList(this.props.disabled)}>
            <div className="dd-header-title">{!this.props.disabled ? headerTitle : this.props.disabledTitle}</div>
            {listOpen
              ? <i className="fa fa-angle-up" style={{ padding: '8px' }}/>
              : <i className="fa fa-angle-down" style={{ padding: '8px' }}/>
            }
          </div>
          {listOpen
          && <div className="dd-dropdown">
            <input className="dd-search-box" type="text" onClick={() => this.searchClick()} onChange={this.search}/>
            <ul className="dd-list" onClick={(e) => e.stopPropagation()}>
              {error && error.show
                && <li className="dd-list-item" style={{
                  color: 'red', fontWeight: '400', textAlign: 'center', whiteSpace: 'normal'
                }}>
                  <i className="fa fa-exclamation-triangle fa-xs"/>
                  <span style={{ marginLeft: '5px' }}>
                    {error.message}
                  </span>
                </li>
              }
              {
                searchedItems && searchedItems.map((item) => (
                    <li className="dd-list-item" key={item.description} onClick={() => this.toggleSelected(item.description)}>
                      {item.description} {item.selected && <i className="fa fa-check" aria-hidden="true" style={{ float: 'right' }}/>}
                    </li>
                ))
              }
            </ul>
            <div className="dd-list-select-button-container">
              <button className="dd-list-select-button dd-reset" onClick={() => this.reset()}>Reset</button>
              <button className="dd-list-select-button dd-submit" onClick={() => this.select()}>Select</button>
            </div>
          </div>
          }
        </div>
    );
  }
}

MultiSelectDropDown.propTypes = {
  titleHelper: PropTypes.string,
  title: PropTypes.string,
  disabledTitle: PropTypes.string,
  id: PropTypes.string,
  list: PropTypes.array,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

export default MultiSelectDropDown;
