import initialState from './initialState';
import {
  RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT,
  HIDE_ERROR_DIALOG,
  RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH,
  RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH,
  RECEIVE_ERROR_IN_832_FILES_FETCH,
  RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USERS_FETCH,
  RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH,
  RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS,
  RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER,
  RECEIVE_ERROR_IN_VENDORS_FETCH,
  RECEIVE_ERROR_IN_OPCO_FETCH,
  RECEIVE_ERROR_IN_REPORT_TEMPLATE_CREATION, RECEIVE_ERROR_IN_REPORT_TEMPLATE_DELETION,
  RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH,
  RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH,
  RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION,
  RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH,
  RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH,
  RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION,
  RECEIVE_ERROR_IN_COST_DATA_FETCH,
  RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT,
  RECEIVE_ERROR_IN_ITEMS_VALIDATION,
  CLEAR_ITEMS_VALIDATION_ERROR,
  RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE,
  RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE,
  RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION,
  RECEIVE_FILE_LOADING_ERROR,
  RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH,
  RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH,
  RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH,
  RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH,
  RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS,
  RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS,
  RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS,
  RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT,
  RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH,
  RECEIVE_ERROR_UPDATE_SUPPLIER_CONTACT
} from '../actions/errorDialogActions';

export default function errorReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.error;
  }
  switch (type) {
    case RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT:
    case RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH:
    case RECEIVE_ERROR_IN_832_FILES_FETCH:
    case RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_USERS_FETCH:
    case RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH:
    case RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS:
    case RECEIVE_ERROR_IN_VENDORS_FETCH:
    case RECEIVE_ERROR_IN_OPCO_FETCH:
    case RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER:
    case RECEIVE_ERROR_IN_REPORT_TEMPLATE_DELETION:
    case RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH:
    case RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH:
    case RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION:
    case RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH:
    case RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION:
    case RECEIVE_ERROR_IN_COST_DATA_FETCH:
    case RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT:
    case RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE:
    case RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE:
    case RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION:
    case RECEIVE_FILE_LOADING_ERROR:
    case RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH:
    case RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH:
    case RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH:
    case RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH:
    case RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS:
    case RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS:
    case RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS:
    case RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT:
    case RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH:
    case RECEIVE_ERROR_UPDATE_SUPPLIER_CONTACT:
      return { show: true, message: data };

    case RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH:
      return { show: true, message: data, isSupplierOnboardDetailsFetchFailed: true };

    case RECEIVE_ERROR_IN_REPORT_TEMPLATE_CREATION:
    case RECEIVE_ERROR_IN_ITEMS_VALIDATION:
      return { show: true, message: data, type: 'text' };

    case HIDE_ERROR_DIALOG:
    case CLEAR_ITEMS_VALIDATION_ERROR:
      return { show: false, message: '' };

    default:
      return state;
  }
}
