import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { switchUserGroups } from '../../internals/actions/userManagementActions';
import MultiSelectDropDown from '../../components/dropDown/multiSelectDropDown';

class UserGroupDropDown extends Component {
    userGroupDropDownOnClose = (values) => {
      this.props.switchUserGroups(values);
    };

    render() {
      const { groups, error } = this.props.user;
      return (
            <MultiSelectDropDown
                id={'app_header_user_group_selection_drop_down'}
                titleHelper="Group"
                title={'Select group'}
                disabledTitle={'Global'}
                list={groups}
                error={error}
                onSubmit={this.userGroupDropDownOnClose}
                disabled={!this.props.isUserGroupSelectionEnabled}
            />
      );
    }
}

UserGroupDropDown.propTypes = {
  user: PropTypes.object,
  switchUserGroups: PropTypes.func,
  isUserGroupSelectionEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.userReducer,
  isUserGroupSelectionEnabled: state.userReducer.isUserGroupSelectionEnabled,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    switchUserGroups,
  }, dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupDropDown);
