/**
 * Workspace Action Types
 *
 * @author ysum5308
 */
export const REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES = 'REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES';
export const RECEIVE_SUPPLIER_SUPPORT_REQUEST_TYPES = 'RECEIVE_SUPPLIER_SUPPORT_REQUEST_TYPES';
export const RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_TYPES_FETCH = 'RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_TYPES_FETCH';

export const SUBMIT_SUPPLIER_SUPPORT_REQUEST = 'SUBMIT_SUPPLIER_SUPPORT_REQUEST';
export const RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE = 'RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE';

export const REQUEST_ALL_HELP_TOPICS = 'REQUEST_ALL_HELP_TOPICS';
export const RECEIVE_ALL_HELP_TOPICS = 'RECEIVE_ALL_HELP_TOPICS';
export const RECEIVE_ERROR_IN_HELP_TOPICS_FETCH = 'RECEIVE_ERROR_IN_HELP_TOPICS_FETCH';

export const REQUEST_HELP_TOPIC_CONTENT = 'REQUEST_HELP_TOPIC_CONTENT';
export const RECEIVE_HELP_TOPIC_CONTENT = 'RECEIVE_HELP_TOPIC_CONTENT';
export const RESET_SELECTED_TOPIC_DETAILS = 'RESET_SELECTED_TOPIC_DETAILS';

export const REQUEST_RECENT_ACTIVITY_DATA = 'REQUEST_RECENT_ACTIVITY_DATA';
export const RECEIVE_RECENT_ACTIVITY_DATA = 'RECEIVE_RECENT_ACTIVITY_DATA';
export const RECEIVE_ERROR_IN_RECENT_ACTIVITY_DATA_FETCH = 'RECEIVE_ERROR_IN_RECENT_ACTIVITY_DATA_FETCH';

export const FETCH_REQUEST_DETAILS = 'FETCH_REQUEST_DETAILS';
export const RECEIVE_REQUEST_DETAILS = 'RECEIVE_REQUEST_DETAILS';
export const RESET_SELECTED_REQUEST_DETAILS = 'RESET_SELECTED_REQUEST_DETAILS';
export const RECEIVE_REQUEST_WITH_VARIATION_DETAILS = 'RECEIVE_REQUEST_WITH_VARIATION_DETAILS';

export const SUBMIT_WORKSPACE_REQUEST = 'SUBMIT_WORKSPACE_REQUEST';
export const RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE = 'RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE';

export const UPDATE_SELECTED_REQUEST_STATUS = 'UPDATE_SELECTED_REQUEST_STATUS';

export const SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE = 'SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE';
export const RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE = 'RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE';

export const ADD_WORKSPACE_REQUEST_COMMENT = 'ADD_WORKSPACE_REQUEST_COMMENT';

export const REQUEST_WORKSPACE_SEARCH_DATA = 'REQUEST_WORKSPACE_SEARCH_DATA';
export const RECEIVE_WORKSPACE_SEARCH_DATA = 'RECEIVE_WORKSPACE_SEARCH_DATA';
export const RECEIVE_ERROR_IN_WORKSPACE_SEARCH_DATA_FETCH = 'RECEIVE_ERROR_IN_WORKSPACE_SEARCH_DATA_FETCH';

export const UPDATE_WORKSPACE_SEARCH_REQUEST_IDS = 'UPDATE_WORKSPACE_SEARCH_REQUEST_IDS';
export const UPDATE_WORKSPACE_SEARCH_REQUEST_FILTERS = 'UPDATE_WORKSPACE_SEARCH_REQUEST_FILTERS';
export const UPDATE_WORKSPACE_SEARCH_TYPE = 'UPDATE_WORKSPACE_SEARCH_TYPE';
export const UPDATE_WORKSPACE_SEARCH_VALIDITY = 'UPDATE_WORKSPACE_SEARCH_VALIDITY';
export const RESET_WORKSPACE_SEARCH = 'RESET_WORKSPACE_SEARCH';
export const UPDATE_WORKSPACE_SEARCH_PRESERVE_STATUS = 'UPDATE_WORKSPACE_SEARCH_PRESERVE_STATUS';

export const PRESERVE_RECENT_ACTIVITY_FILTER_ATTRIBUTES = 'PRESERVE_RECENT_ACTIVITY_FILTER_ATTRIBUTES';
export const RETAIN_RECENT_ACTIVITY_FILTER = 'RETAIN_RECENT_ACTIVITY_FILTER';
export const RESET_RECENT_ACTIVITY_FILTER_RETENTION = 'RESET_RECENT_ACTIVITY_FILTER_RETENTION';

export const requestSupplierSupportRequestTypes = () => (
  {
    type: REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES,
  });

export const receiveSupplierSupportRequestTypes = (data) => (
  {
    type: RECEIVE_SUPPLIER_SUPPORT_REQUEST_TYPES,
    data
  });

export const receiveErrorInSupplierSupportRequestTypesFetch = (data) => (
  {
    type: RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_TYPES_FETCH,
    data
  });

export const submitSupplierSupportRequest = (data) => (
  {
    type: SUBMIT_SUPPLIER_SUPPORT_REQUEST,
    data
  });

export const receiveSupplierSupportRequestSubmissionResponse = (data) => (
  {
    type: RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE,
    data
  });

export const requestAllHelpTopics = () => (
  {
    type: REQUEST_ALL_HELP_TOPICS,
  });

export const receiveAllHelpTopics = (data) => (
  {
    type: RECEIVE_ALL_HELP_TOPICS,
    data
  });

export const requestHelpTopicContent = (topicId) => (
  {
    type: REQUEST_HELP_TOPIC_CONTENT,
    data: { topicId }
  });

export const receiveHelpTopicContent = (data) => (
  {
    type: RECEIVE_HELP_TOPIC_CONTENT,
    data
  });

export const receiveErrorInHelpTopicsFetch = (data) => (
  {
    type: RECEIVE_ERROR_IN_HELP_TOPICS_FETCH,
    data
  });

export const resetSelectedTopicDetails = () => (
  {
    type: RESET_SELECTED_TOPIC_DETAILS
  });

export const requestRecentActivityData = (data) => (
  {
    type: REQUEST_RECENT_ACTIVITY_DATA,
    data
  });

export const receiveRecentActivityData = (data) => (
  {
    type: RECEIVE_RECENT_ACTIVITY_DATA,
    data
  });

export const receiveErrorInRecentActivityDataFetch = (data) => (
  {
    type: RECEIVE_ERROR_IN_RECENT_ACTIVITY_DATA_FETCH,
    data
  });

export const fetchRequestDetails = (requestId) => (
  {
    type: FETCH_REQUEST_DETAILS,
    data: { requestId }
  });

export const receiveRequestDetails = (data) => (
  {
    type: RECEIVE_REQUEST_DETAILS,
    data
  });

export const receiveRequestWithVariationDetails = (data) => (
  {
    type: RECEIVE_REQUEST_WITH_VARIATION_DETAILS,
    data
  });

export const resetSelectedRequestDetails = () => (
  {
    type: RESET_SELECTED_REQUEST_DETAILS
  });

export const submitWorkspaceRequest = (data) => (
  {
    type: SUBMIT_WORKSPACE_REQUEST,
    data
  });

export const receiveWorkspaceRequestSubmissionResponse = (data) => (
  {
    type: RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE,
    data
  });

export const updateSelectedRequestStatus = (data) => (
  {
    type: UPDATE_SELECTED_REQUEST_STATUS,
    data
  });

export const submitWorkspaceRequestActionChange = (data) => (
  {
    type: SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE,
    data
  });

export const receiveWorkspaceRequestActionResponse = (data) => (
  {
    type: RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE,
    data
  });

export const addWorkspaceRequestComment = (data) => (
  {
    type: ADD_WORKSPACE_REQUEST_COMMENT,
    data
  });

export const requestWorkspaceSearchData = (data) => (
  {
    type: REQUEST_WORKSPACE_SEARCH_DATA,
    data
  });

export const receiveWorkspaceSearchData = (data) => (
  {
    type: RECEIVE_WORKSPACE_SEARCH_DATA,
    data
  });

export const receiveErrorInWorkspaceSearchDataFetch = (data) => (
  {
    type: RECEIVE_ERROR_IN_WORKSPACE_SEARCH_DATA_FETCH,
    data
  });

export const updateWorkspaceSearchRequestIds = (data) => (
  {
    type: UPDATE_WORKSPACE_SEARCH_REQUEST_IDS,
    data
  });

export const updateWorkspaceSearchRequestFilters = (data) => (
  {
    type: UPDATE_WORKSPACE_SEARCH_REQUEST_FILTERS,
    data
  });

export const updateWorkspaceSearchType = (data) => (
  {
    type: UPDATE_WORKSPACE_SEARCH_TYPE,
    data
  });

export const updateWorkspaceSearchValidity = (data) => (
  {
    type: UPDATE_WORKSPACE_SEARCH_VALIDITY,
    data
  });

export const resetWorkspaceSearch = () => (
  {
    type: RESET_WORKSPACE_SEARCH,
  });

export const updateWorkspaceSearchPreserveStatus = (data) => (
  {
    type: UPDATE_WORKSPACE_SEARCH_PRESERVE_STATUS,
    data
  });

export const preserveRecentActivityFilterAttributes = (data) => (
  {
    type: PRESERVE_RECENT_ACTIVITY_FILTER_ATTRIBUTES,
    data
  });

export const retainRecentActivityFilter = () => (
  {
    type: RETAIN_RECENT_ACTIVITY_FILTER
  });

export const resetRecentActivityFilterRetention = () => (
  {
    type: RESET_RECENT_ACTIVITY_FILTER_RETENTION
  });
