/**
 * Created by hgun0888
 * 12/3/18
 * */

/**
 * Action Types
 */
export const REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG = 'REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG';
export const RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG = 'RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG';
export const requestVendorPriceFilesImportLog = ({
  username, suvc,
  fileName, fileType, startTime, endTime, businessProcessId, page, pageSize, sorted, userGroups
}) => (
  {
    type: REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG,
    data: {
      username,
      suvc,
      fileName,
      fileType,
      startTime,
      endTime,
      businessProcessId,
      page,
      pageSize,
      sorted,
      userGroups
    }
  });

export const receiveVendorPriceFilesImportLog = (data) => (
  {
    type: RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG,
    data
  });
