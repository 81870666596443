/**
 * Created by ysum5308
 * */
import initialState from './initialState';
import {
  REQUEST_ALL_HELP_TOPICS,
  RECEIVE_ALL_HELP_TOPICS,
  RECEIVE_ERROR_IN_HELP_TOPICS_FETCH,
  RECEIVE_HELP_TOPIC_CONTENT,
  RESET_SELECTED_TOPIC_DETAILS,
} from '../actions/workspaceActions';

export default function helpTopicsReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.workspace.helpTopics;
  }

  if (type === REQUEST_ALL_HELP_TOPICS) {
    return { ...state, loading: true, error: { show: false, message: '' } };
  }

  if (type === RECEIVE_ALL_HELP_TOPICS) {
    return { ...state, data: data.helpTopics, loading: false };
  }

  if (type === RECEIVE_ERROR_IN_HELP_TOPICS_FETCH) {
    return { ...state, loading: false, error: { show: true, message: data } };
  }

  if (type === RECEIVE_HELP_TOPIC_CONTENT) {
    return { ...state, selectedHelpTopicDetails: data };
  }

  if (type === RESET_SELECTED_TOPIC_DETAILS) {
    return { ...state, selectedHelpTopicDetails: {} };
  }

  return state;
}
