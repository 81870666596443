/**
 * Created by hgun0888
 * 12/3/18
 * */

/**
 * Action Types
 */
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const FETCH_DATADOG_SECRETS = 'FETCH_DATADOG_SECRETS';
export const FETCH_DATADOG_SECRETS_ERROR = 'FETCH_DATADOG_SECRETS_ERROR';
export const SWITCH_USER_GROUPS = 'SWITCH_USER_GROUPS';
export const CHANGE_USER_GROUP_SELECTION_STATUS = 'CHANGE_USER_GROUP_SELECTION_STATUS';
export const LOG_OUT = 'LOG_OUT';
export const REQUEST_USER_PROFILE_DETAILS = 'REQUEST_USER_PROFILE_DETAILS';
export const REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER = 'REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER';
export const RECEIVE_USER_PROFILE_DETAILS = 'RECEIVE_USER_PROFILE_DETAILS';
export const RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER = 'RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER';
export const REQUEST_USER_GROUP_ASSOCIATION_DETAILS = 'REQUEST_USER_GROUP_ASSOCIATION_DETAILS';
export const RECEIVE_USER_GROUP_ASSOCIATION_DETAILS = 'RECEIVE_USER_GROUP_ASSOCIATION_DETAILS';
export const FETCH_USERS = 'FETCH_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const FETCH_ALL_USER_GROUPS = 'FETCH_ALL_USER_GROUPS';
export const RECEIVE_ALL_USER_GROUPS = 'RECEIVE_ALL_USER_GROUPS';
export const REQUEST_USER_PROFILE_MANAGEMENT_DETAILS = 'REQUEST_USER_PROFILE_MANAGEMENT_DETAILS';
export const RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS = 'RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS';
export const REQUEST_USER_GROUP_DETAILS = 'REQUEST_USER_GROUP_DETAILS';
export const RECEIVE_USER_GROUP_DETAILS = 'RECEIVE_USER_GROUP_DETAILS';
export const RESET_USER_GROUP_DETAILS = 'RESET_USER_GROUP_DETAILS';

export const SELECT_SUVC_ASSOCIATION_FOR_USER = 'SELECT_SUVC_ASSOCIATION_FOR_USER';
export const SELECT_VENDOR_ASSOCIATION_FOR_USER = 'SELECT_VENDOR_ASSOCIATION_FOR_USER';
export const SELECT_SUVC_ASSOCIATION_TYPE_FOR_USER = 'SELECT_SUVC_ASSOCIATION_TYPE_FOR_USER';
export const SELECT_VENDOR_ASSOCIATION_TYPE_FOR_USER = 'SELECT_VENDOR_ASSOCIATION_TYPE_FOR_USER';
export const SELECT_DIRECT_GROUP_ASSOCIATION_FOR_USER = 'SELECT_DIRECT_GROUP_ASSOCIATION_FOR_USER';
export const CHANGE_SUPERVISOR_FOR_USER = 'CHANGE_SUPERVISOR_FOR_USER';
export const SAVE_SUPERVISOR_FOR_USER = 'SAVE_SUPERVISOR_FOR_USER';
export const REMOVE_SUPERVISOR_OF_USER = 'REMOVE_SUPERVISOR_OF_USER';
export const RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER = 'RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER';
export const RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER = 'RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER';
export const SAVE_VENDORS_ASSOCIATION_FOR_USER = 'SAVE_VENDORS_ASSOCIATION_FOR_USER';
export const RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER = 'RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER';

export const SAVE_GROUP = 'SAVE_GROUP';
export const RECEIVE_GROUP_PERSIST_STATUS = 'RECEIVE_GROUP_PERSIST_STATUS';
export const SAVE_DIRECT_USER_GROUP_ASSOCIATION = 'SAVE_DIRECT_USER_GROUP_ASSOCIATION';
export const RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS = 'RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS';

export const RESET_USER_PROFILE_MANAGEMENT_DATA = 'RESET_USER_PROFILE_MANAGEMENT_DATA';
export const SHOW_MODAL_FOR_SELECTED_USER = 'SHOW_MODAL_FOR_SELECTED_USER';
export const HIDE_MODAL_FOR_SELECTED_USER = 'HIDE_MODAL_FOR_SELECTED_USER';

export const SAVE_SUPPLIER = 'SAVE_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const RECEIVE_SUPPLIER_PERSIST_STATUS = 'RECEIVE_SUPPLIER_PERSIST_STATUS';
export const RECEIVE_SUPPLIER_UPDATE_STATUS = 'RECEIVE_SUPPLIER_UPDATE_STATUS';

export const REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS = 'REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS';
export const RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS = 'RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS';

export const REQUEST_SUPPLIER_ONBOARD_DETAILS = 'REQUEST_SUPPLIER_ONBOARD_DETAILS';
export const RECEIVE_SUPPLIER_ONBOARD_DETAILS = 'RECEIVE_SUPPLIER_ONBOARD_DETAILS';
export const RESET_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS = 'RESET_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS';
export const TRIGGER_UPDATE_SUPPLIER_CONTACT = 'TRIGGER_UPDATE_SUPPLIER_CONTACT';
export const SUCCESS_UPDATE_SUPPLIER_CONTACT = 'SUCCESS_UPDATE_SUPPLIER_CONTACT';

/**
 * Actions
 */
export const logInSuccess = (data) => ({ type: LOG_IN_SUCCESS, data });
export const fetchDatadogSecrets = (data) => ({ type: FETCH_DATADOG_SECRETS, data });
export const fetchDatadogSecretsError = (data) => ({ type: FETCH_DATADOG_SECRETS_ERROR, data });
export const logoutUser = () => ({ type: LOG_OUT });

export const switchUserGroups = (data) => ({ type: SWITCH_USER_GROUPS, data });
export const changeUserGroupSelectionStatus = (data) => ({ type: CHANGE_USER_GROUP_SELECTION_STATUS, data });

export const requestUserProfileDetails = (networkId) => ({ type: REQUEST_USER_PROFILE_DETAILS, data: { networkId } });
export const receiveUserProfileDetails = (data) => ({ type: RECEIVE_USER_PROFILE_DETAILS, data });

export const requestUserGroupAssociationDetails = (networkId) => ({ type: REQUEST_USER_GROUP_ASSOCIATION_DETAILS, data: { networkId } });
export const receiveUserGroupAssociationDetails = (data) => ({ type: RECEIVE_USER_GROUP_ASSOCIATION_DETAILS, data });

export const showModalForSelectedUser = (networkId) => ({ type: SHOW_MODAL_FOR_SELECTED_USER, data: { networkId } });
export const hideModalForSelectedUser = (networkId) => ({ type: HIDE_MODAL_FOR_SELECTED_USER, data: { networkId } });

export const requestUserProfileDetailsOfSelectedUser = (networkId) => ({ type: REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER, data: { networkId } });
export const receiveUserProfileDetailsOfSelectedUser = (data) => ({ type: RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER, data });

export const requestUserProfileManagementDetails = (data) => ({ type: REQUEST_USER_PROFILE_MANAGEMENT_DETAILS, data });
export const receiveUserProfileManagementDetails = (data) => ({ type: RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS, data });

export const requestUserGroupDetails = (data) => ({ type: REQUEST_USER_GROUP_DETAILS, data });
export const receiveUserGroupDetails = (data) => ({ type: RECEIVE_USER_GROUP_DETAILS, data });
export const resetUserGroupDetails = () => ({ type: RESET_USER_GROUP_DETAILS });

export const selectSuvcAssociationForUser = (row, value) => ({
  type: SELECT_SUVC_ASSOCIATION_FOR_USER,
  data: {
    row, value
  }
});
export const selectVendorAssociationForUser = (row, value) => ({
  type: SELECT_VENDOR_ASSOCIATION_FOR_USER,
  data: {
    row, value
  }
});
export const selectSuvcAssociationTypeForUser = (row, value) => ({
  type: SELECT_SUVC_ASSOCIATION_TYPE_FOR_USER,
  data: {
    row, value
  }
});
export const selectVendorAssociationTypeForUser = (row, value) => ({
  type: SELECT_VENDOR_ASSOCIATION_TYPE_FOR_USER,
  data: {
    row, value
  }
});

export const selectDirectGroupAssociationForUser = (data) => ({ type: SELECT_DIRECT_GROUP_ASSOCIATION_FOR_USER, data });

export const changeSupervisorForUser = (data) => ({ type: CHANGE_SUPERVISOR_FOR_USER, data });
export const saveSupervisor = (data) => ({ type: SAVE_SUPERVISOR_FOR_USER, data });
export const removeSupervisor = (data) => ({ type: REMOVE_SUPERVISOR_OF_USER, data });
export const receiveSupervisorPersistStatus = (data) => ({ type: RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER, data });
export const receiveSupervisorRemoveStatus = (data) => ({ type: RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER, data });

export const saveAssociatedVendors = (data) => ({ type: SAVE_VENDORS_ASSOCIATION_FOR_USER, data });
export const receiveVendorAssociationsPersistStatus = (data) => ({ type: RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER, data });

export const saveGroup = (data) => ({ type: SAVE_GROUP, data });
export const receiveGroupPersistStatus = (data) => ({ type: RECEIVE_GROUP_PERSIST_STATUS, data });

export const saveDirectUserGroupAssociations = (data) => ({ type: SAVE_DIRECT_USER_GROUP_ASSOCIATION, data });
export const receiveDirectUserGroupAssociationsPersistStatus = (data) => ({ type: RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS, data });

export const fetchUsers = (data) => ({ type: FETCH_USERS, data });
export const receiveUsers = (data) => ({ type: RECEIVE_USERS, data });

export const fetchAllUserGroups = () => ({ type: FETCH_ALL_USER_GROUPS });
export const receiveAllUserGroups = (data) => ({ type: RECEIVE_ALL_USER_GROUPS, data });

export const resetUserProfileManagementData = () => ({ type: RESET_USER_PROFILE_MANAGEMENT_DATA });

export const saveSupplier = (data) => ({ type: SAVE_SUPPLIER, data });
export const receiveSupplierPersistStatus = (data) => ({ type: RECEIVE_SUPPLIER_PERSIST_STATUS, data });

export const updateSupplier = (data) => ({ type: UPDATE_SUPPLIER, data });
export const receiveSupplierUpdatetStatus = () => ({ type: RECEIVE_SUPPLIER_UPDATE_STATUS });

export const requestUserAssociationAndBusinessCenterContacts = (suvc) => ({ type: REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS, data: { suvc } });
export const receiveUserAssociationAndBusinessCenterContacts = (data) => ({ type: RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS, data });

export const requestSupplierOnboardDetails = (userId) => ({ type: REQUEST_SUPPLIER_ONBOARD_DETAILS, data: { userId } });
export const receiveSupplierOnboardDetails = (data) => ({ type: RECEIVE_SUPPLIER_ONBOARD_DETAILS, data });

export const resetUserAssociationAndBusinessCenterContacts = () => ({ type: RESET_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS });

export const triggerUpdateSupplierContact = (data) => ({ type: TRIGGER_UPDATE_SUPPLIER_CONTACT, data });
export const successUpdateSupplierContact = (data) => ({ type: SUCCESS_UPDATE_SUPPLIER_CONTACT, data });
