/**
 * Cost Maintenance Action Types
 *
 * @author ysum5308
 */
export const VALIDATE_ITEMS_BY_SUPCS = 'VALIDATE_ITEMS_BY_SUPCS';
export const RECEIVE_ITEM_VALIDATION_RESPONSE = 'RECEIVE_ITEM_VALIDATION_RESPONSE';
export const RESET_ITEM_VALIDATION_RESPONSE = 'RESET_ITEM_VALIDATION_RESPONSE';

export const REQUEST_COST_DATA = 'REQUEST_COST_DATA';
export const RECEIVE_COST_DATA = 'RECEIVE_COST_DATA';
export const SUBMIT_GENERATE_COST_REPORTS = 'SUBMIT_GENERATE_COST_REPORTS';

export const REQUEST_ADHOC_COST_FILE_IMPORT = 'REQUEST_ADHOC_COST_FILE_IMPORT';
export const REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT = 'REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT';

export const RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE = 'RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE';
export const RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE = 'RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE';

export const RESET_ADHOC_COST_DATA = 'RESET_ADHOC_COST_DATA';

export const REQUEST_BUSINESS_CENTER_DETAILS = 'REQUEST_BUSINESS_CENTER_DETAILS';
export const RECEIVE_BUSINESS_CENTER_DETAILS = 'RECEIVE_BUSINESS_CENTER_DETAILS';
export const RESET_BUSINESS_CENTER_DETAILS = 'RESET_BUSINESS_CENTER_DETAILS';

export const REQUEST_PRICE_RECORD_TYPES = 'REQUEST_PRICE_RECORD_TYPES';
export const RECEIVE_PRICE_RECORD_TYPES = 'RECEIVE_PRICE_RECORD_TYPES';
export const RESET_PRICE_RECORD_TYPES = 'RESET_PRICE_RECORD_TYPES';

export const validateItemsBySupcs = (data) => (
  {
    type: VALIDATE_ITEMS_BY_SUPCS,
    data
  });

export const receiveItemValidationResponse = (data) => (
  {
    type: RECEIVE_ITEM_VALIDATION_RESPONSE,
    data
  });

export const resetItemValidationResponse = () => (
  { type: RESET_ITEM_VALIDATION_RESPONSE });

export const requestCostData = (data) => (
  {
    type: REQUEST_COST_DATA,
    data
  });

export const receiveCostData = (data) => (
  {
    type: RECEIVE_COST_DATA,
    data
  });

export const requestAdhocCostFileImport = (data) => (
  {
    type: REQUEST_ADHOC_COST_FILE_IMPORT,
    data
  }
);

export const resetAdhocCostData = () => (
  { type: RESET_ADHOC_COST_DATA });

export const requestBusinessCenterDetails = (data) => (
  {
    type: REQUEST_BUSINESS_CENTER_DETAILS,
    data
  });

export const receiveBusinessCenterDetails = (data) => (
  {
    type: RECEIVE_BUSINESS_CENTER_DETAILS,
    data
  });

export const resetBusinessCenterDetails = () => (
  { type: RESET_BUSINESS_CENTER_DETAILS });

export const requestPriceRecordTypes = (data) => (
  {
    type: REQUEST_PRICE_RECORD_TYPES,
    data
  });

export const receivePriceRecordTypes = (data) => (
  {
    type: RECEIVE_PRICE_RECORD_TYPES,
    data
  });

export const resetPriceRecordTypes = () => (
  { type: RESET_PRICE_RECORD_TYPES });

export const submitGenerateCostReports = (data) => (
  {
    type: SUBMIT_GENERATE_COST_REPORTS,
    data
  });

export const requestMultipleAdhocCostFileImport = (data) => (
  {
    type: REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT,
    data
  });

export const receiveAdhocCostFileImportResponse = (data) => (
  {
    type: RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE,
    data
  });

export const receiveMultipleAdhocCostFileImportResponse = (data) => (
  {
    type: RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE,
    data
  });
