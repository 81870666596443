/**
 * Created by ngan4172
 * */
import initialState from './initialState';
import {
  RECEIVE_BUSINESS_CENTERS
} from '../actions/agreementActions';

export default function businessCentersReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.businessCenters;
  }

  if (type === RECEIVE_BUSINESS_CENTERS) {
    return data;
  }

  return state;
}
