/**
 * Created by aheshperera
 * 12/3/18
 * */

import initialState from './initialState';
import { RECEIVE_REPORTS_LOG } from '../actions/reportsActions';

export default function reportsLogReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.reportsLog;
  }

  if (type === RECEIVE_REPORTS_LOG) {
    return { ...state, reportDetails: data };
  }
  return state;
}
