import React from 'react';
import {
  BrowserRouter as AppRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import { stringify } from 'qs';
import { URLS } from './helpers/constants';
import { responseStatusValidator } from './util/validator';

/**
 * Redux Dependencies
 */

import { store, persistor } from './internals/store';

/**
 * Containers
 */
import { BaseLayoutContainer } from './baseContainer';
import { Login } from './views/Login/login';
import { LandingPage } from './views/LandingPage/landingPage';
import { SplashPage } from './views/SplashPage/splashPage';
import CookieConsentBanner from './views/Cookies/cookieConsentBanner';

/**
 * Set the store and routes to App
 */

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    store.getState().userReducer && store.getState().userReducer.active === true
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location },
      }} {...props} />
  )} />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.runInitScript();
  }

  render = () => (
    <React.Fragment>
      <CookieConsentBanner />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter>
            <Switch>
              <Route path='/welcome' component={SplashPage} />
              <Route path='/login_callback' component={Login} />
              <Route path='/login' component={LandingPage} />
              <Route path='/download' render={({ location: { search } }) => {
                window.location.href = `${URLS.ATTACHMENT_DOWNLOAD_URL}${search}`;
              }} />
              <PrivateRoute path='/' component={BaseLayoutContainer} />
            </Switch>
          </AppRouter>
        </PersistGate>
      </Provider>
    </React.Fragment>
  );

  runInitScript = () => {
    this.initAxiosInterceptor();
  };

  initAxiosInterceptor = () => {
    axios.defaults.withCredentials = true;
    axios.defaults.paramsSerializer = (params) => stringify(params);
    axios.defaults.validateStatus = (status) => responseStatusValidator(status, store.getState().cookieReducer.isCookie);
  }
}

PrivateRoute.propTypes = {
  location: PropTypes.any,
  component: PropTypes.func,
};

export default App;
