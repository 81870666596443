import initialState from './initialState';
import { isArrayContentEqual } from '../../util/validator';
import {
  RECEIVE_ALL_USER_GROUPS,
  SELECT_DIRECT_GROUP_ASSOCIATION_FOR_USER,
  RECEIVE_GROUP_PERSIST_STATUS,
  RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS,
  RECEIVE_USER_GROUP_DETAILS,
  RESET_USER_GROUP_DETAILS
} from '../actions/userManagementActions';

function selectGroupAssociation(state, data) {
  const { initialStateForUserGroup, directUserGroupAssociationMap } = state;
  const selectedUserGroupAssociations = data.map(({ groupCode }) => groupCode);
  const isUserGroupChanged = !isArrayContentEqual(initialStateForUserGroup, selectedUserGroupAssociations);

  Object.keys(directUserGroupAssociationMap).forEach((groupCode) => {
    directUserGroupAssociationMap[groupCode].selected = selectedUserGroupAssociations.includes(groupCode);
  });

  return isUserGroupChanged;
}

export default function userGroupReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.userManagement;
  }

  if (type === RECEIVE_ALL_USER_GROUPS) {
    const groups = data.map((group, index) => {
      group.id = index;
      return group;
    });
    return { ...state, groups };
  }

  if (type === RECEIVE_USER_GROUP_DETAILS) {
    const directUserGroupAssociationMap = {};
    const directUserGroupAssociations = data.directUserGroupAssociationDetails.map(({ code }) => code);

    data.groups.forEach(({ code, name, description }) => {
      const selected = directUserGroupAssociations.includes(code);
      directUserGroupAssociationMap[code] = { name, description, selected };
    });

    return {
      ...state, directUserGroupAssociationMap, initialStateForUserGroup: directUserGroupAssociations
    };
  }

  if (type === RESET_USER_GROUP_DETAILS) {
    return {
      ...state, directUserGroupAssociationMap: {}, isUserGroupChanged: false, initialStateForUserGroup: []
    };
  }

  if (type === RECEIVE_GROUP_PERSIST_STATUS) {
    const { groups } = state;
    data.group.id = groups.length;
    groups.push(data.group);
    return { ...state, groups: groups.slice() };
  }

  if (type === RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS) {
    const initialStateForUserGroup = data.groups.map(({ groupCode }) => groupCode);
    return { ...state, isUserGroupChanged: false, initialStateForUserGroup };
  }

  if (type === SELECT_DIRECT_GROUP_ASSOCIATION_FOR_USER) {
    const isUserGroupChanged = selectGroupAssociation({ ...state }, data);
    return { ...state, isUserGroupChanged };
  }

  return state;
}
