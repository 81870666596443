/**
 * Created by aheshperera
 * 12/3/18
 * */

import initialState from './initialState';
import { RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG } from '../actions/filesLogActions';

export default function filesLogReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.filesLog;
  }

  if (type === RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG) {
    return { ...state, vendorPriceFilesImportDetails: data };
  }
  return state;
}
