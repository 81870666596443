import axios from 'axios';
import { URLS, HTTP_STATUS } from '../helpers/constants';

/**
 * Fetch cookie
 *
 * @author skit1169
 */

class CookieProxy {
  static async fetchCookie() {
    try {
      const response = await axios.get(`${URLS.GET_COOKIE_URL}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
        return false;
      }
      return true;
    }
  }
}

export default CookieProxy;
