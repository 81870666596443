import { call, put, takeEvery } from 'redux-saga/effects';
import agreementApi from '../../externals/agreementProxy';
import {
  UNABLE_TO_LOAD_AGREEMENT_DATA,
  UNABLE_TO_LOAD_VENDORS,
  UNABLE_TO_LOAD_BUSINESS_CENTERS,
  UNABLE_TO_LOAD_SAS_FD_DETAILS,
  UNABLE_TO_SAVE_DETAILS,
  FILE_IMPORT_ERROR,
} from '../../helpers/messageConstant';
import {
  receiveErrorInVendorsFetch,
  receiveErrorInBusinessCentersFetch,
  receiveErrorInSASFDDetailsFetch,
  receiveErrorWhenSavingVendorSASDetails,
  receiveErrorWhenAddingVendorSASDetails,
  receiveErrorInSASFDFileImport,
  receiveErrorWhenSavingItemSasFdDetails,
} from '../actions/errorDialogActions';
import {
  FETCH_AGREEMENT_DATA,
  receiveAgreementData,
  receiveErrorInAgreementFetch,
  FETCH_VENDOR_BASES,
  receiveVendorBases,
  FETCH_BUSINESS_CENTERS,
  receiveBusinessCenters,
  FETCH_SAS_FD_DETAILS,
  receiveSASFDDetails,
  SAVE_SAS_VALUE_FOR_VENDOR,
  receiveSASValueUpdateStatus,
  ADD_SAS_VALUE_FOR_VENDOR,
  REQUEST_SAS_FD_FILE_IMPORT,
  receiveSASFDFileImport,
  receiveSASValueAddStatus,
  SAVE_SAS_FD_DETAILS_FOR_ITEM,
  receiveSasFdDetailsForItems,
} from '../actions/agreementActions';
/**
 * Agreement Saga Class
 * @author skit1169
 */
export function* fetchAgreementData({ data }) {
  try {
    const agreementData = yield call(agreementApi.fetchAgreementSearchData, data);
    const agreementDataMap = {};
    agreementDataMap[data.key] = agreementData;
    yield put(receiveAgreementData(agreementDataMap));
  } catch (e) {
    yield put(receiveErrorInAgreementFetch(UNABLE_TO_LOAD_AGREEMENT_DATA));
  }
}

export function* fetchVendorBases() {
  try {
    const vendorBases = yield call(agreementApi.fetchVendorBases);
    yield put(receiveVendorBases(vendorBases));
  } catch (e) {
    yield put(receiveErrorInVendorsFetch(UNABLE_TO_LOAD_VENDORS));
  }
}

export function* fetchBusinessCenters({ data }) {
  try {
    const businessCenters = yield call(agreementApi.fetchBusinessCenters, data);
    yield put(receiveBusinessCenters(businessCenters));
  } catch (e) {
    yield put(receiveErrorInBusinessCentersFetch(UNABLE_TO_LOAD_BUSINESS_CENTERS));
  }
}

export function* fetchSASFDDetails({ data }) {
  try {
    const sasFDDetails = yield call(agreementApi.fetchSASFDDetails, data);
    yield put(receiveSASFDDetails(sasFDDetails));
  } catch (e) {
    yield put(receiveErrorInSASFDDetailsFetch(UNABLE_TO_LOAD_SAS_FD_DETAILS));
  }
}

export function* saveSASValueForVendor({ data }) {
  try {
    const response = yield call(agreementApi.updateSASValueDetails, data);
    yield put(receiveSASValueUpdateStatus(response));
  } catch (e) {
    const errorMessage = e.response.data ? e.response.data : UNABLE_TO_SAVE_DETAILS;
    yield put(receiveErrorWhenSavingVendorSASDetails(errorMessage));
  }
}

export function* importSASFDFile({ data }) {
  try {
    yield call(agreementApi.importSASFDFile, data);
    yield put(receiveSASFDFileImport('Successfully submitted the SAS/FD file to process'));
  } catch (e) {
    yield put(receiveErrorInSASFDFileImport(FILE_IMPORT_ERROR));
  }
}

export function* addSASValueForVendor({ data }) {
  try {
    const response = yield call(agreementApi.addSASValueDetails, data);
    yield put(receiveSASValueAddStatus(response));
  } catch (e) {
    const errorMessage = e.response.data ? e.response.data : UNABLE_TO_SAVE_DETAILS;
    yield put(receiveErrorWhenAddingVendorSASDetails(errorMessage));
  }
}

export function* saveSasFdDetailsForItems({ data }) {
  try {
    const response = yield call(agreementApi.updateSasFdDetails, data);
    yield put(receiveSasFdDetailsForItems(response));
  } catch (e) {
    const errorMessage = e.response.data ? e.response.data : UNABLE_TO_SAVE_DETAILS;
    yield put(receiveErrorWhenSavingItemSasFdDetails(errorMessage));
  }
}

export function* agreementSaga() {
  yield takeEvery(FETCH_AGREEMENT_DATA, fetchAgreementData);
  yield takeEvery(FETCH_VENDOR_BASES, fetchVendorBases);
  yield takeEvery(FETCH_BUSINESS_CENTERS, fetchBusinessCenters);
  yield takeEvery(FETCH_SAS_FD_DETAILS, fetchSASFDDetails);
  yield takeEvery(SAVE_SAS_VALUE_FOR_VENDOR, saveSASValueForVendor);
  yield takeEvery(REQUEST_SAS_FD_FILE_IMPORT, importSASFDFile);
  yield takeEvery(ADD_SAS_VALUE_FOR_VENDOR, addSASValueForVendor);
  yield takeEvery(SAVE_SAS_FD_DETAILS_FOR_ITEM, saveSasFdDetailsForItems);
}
