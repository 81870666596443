/**
 * Created by aheshperera
 * 12/3/18
 * */

import initialState from './initialState';
import {
  CLEAR_CURRENT_VENDOR_FILES_LIST,
  RECEIVE_VENDOR_FILE_IMPORT,
  RECEIVE_VENDOR_FILES_PROCESS_STATUS
} from '../actions/filesActions';
import {
  RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE
} from '../actions/costMaintenanceActions';

function updateFileDetails(filesList, data, length) {
  filesList.forEach((item) => {
    const fileDetails = item;
    for (let i = 0; i < length; i += 1) {
      const dt = data[i];
      if (fileDetails.processId === dt.processId) {
        fileDetails.status = dt.status;
        fileDetails.message = dt.message;
        fileDetails.completionTimeStamp = dt.endTime / 1000;
        break;
      }
    }
  });
}

export default function vendorFileReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.vendorImportFiles;
  }

  if (type === RECEIVE_VENDOR_FILE_IMPORT) {
    if (data) {
      return [...data, ...state];
    }
  }
  if (type === RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE) {
    if (data) {
      return [...data, ...state];
    }
  }

  if (type === CLEAR_CURRENT_VENDOR_FILES_LIST) {
    return [];
  }

  if (type === RECEIVE_VENDOR_FILES_PROCESS_STATUS) {
    const length = data ? data.length : 0;
    if (length > 0) {
      const filesList = [...state];
      updateFileDetails(filesList, data, length);
      return filesList;
    }
  }
  return state;
}
