/**
 * Created by fris4874
 * 12/10/23
 * */

/**
 * React Modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';
/**
 * Custom Modules
 */
import utilitiesApi from '../../externals/utilitiesProxy';
import usersApi from '../../externals/usersProxy';
import { UNABLE_TO_FETCH_EARNED_INCOME_MAPPINGS } from '../../helpers/messageConstant';
import { FETCH_EARNED_INCOME_MAPPINGS, receiveEarnedIncomeMappings } from '../actions/utilitiesActions';
import { receiveErrorInEarnedIncomeMappingsFetch } from '../actions/errorDialogActions';

/**
 * Worker Saga: will be fired on utilities related actions
 */
export function* fetchEarnedIncomeMappings({ data }) {
  try {
    let modifiedBy = '';
    const earnedIncomeMappings = yield call(utilitiesApi.getEarnedIncomeMappings, data);
    try {
      if (earnedIncomeMappings.lastChgId) {
        const profileDetails = yield call(usersApi.fetchUserProfileDetails, earnedIncomeMappings.lastChgId);
        if (profileDetails.name) {
          modifiedBy = profileDetails.name;
        }
      }
    } catch (error) {
      modifiedBy = 'N/A';
    }
    yield put(receiveEarnedIncomeMappings({ earnedIncomeMappings, modifiedBy }));
  } catch (e) {
    yield put(receiveErrorInEarnedIncomeMappingsFetch(UNABLE_TO_FETCH_EARNED_INCOME_MAPPINGS));
  }
}

/**
 * Starts fetching utilities related actions.
 */
export function* utilitiesSaga() {
  yield takeEvery(FETCH_EARNED_INCOME_MAPPINGS, fetchEarnedIncomeMappings);
}
