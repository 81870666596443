import initialState from './initialState';
import { RECEIVE_EARNED_INCOME_MAPPINGS, RESET_EARNED_INCOME_MAPPINGS } from '../actions/utilitiesActions';

export default function utilitiesReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.utilities;
  }

  if (type === RECEIVE_EARNED_INCOME_MAPPINGS) {
    return {
      ...state,
      mappingControl: {
        mappingControlData: data.earnedIncomeMappings,
        isMappingControlResponseReceived: true,
        modifiedBy: data.modifiedBy
      }
    };
  }

  if (type === RESET_EARNED_INCOME_MAPPINGS) {
    return { ...state, mappingControl: initialState.utilities.mappingControl };
  }

  return state;
}
