/**
 * Created by aheshperera
 * 12/3/18
 * */

import initialState from './initialState';
import {
  CLEAR_MASS_AGREEMENT_FILES_LIST, RECEIVE_MASS_AGREEMENT_FILE_IMPORT
} from '../actions/filesActions';

export default function agreementFileReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.agreementImportFiles;
  }

  if (type === RECEIVE_MASS_AGREEMENT_FILE_IMPORT) {
    if (data) {
      return [...data, ...state];
    }
  }

  if (type === CLEAR_MASS_AGREEMENT_FILES_LIST) {
    return [];
  }

  return state;
}
