/**
 * Created by ysum5308
 * */

import initialState from './initialState';
import {
  REQUEST_BUSINESS_CENTER_DETAILS,
  RECEIVE_BUSINESS_CENTER_DETAILS,
  RESET_BUSINESS_CENTER_DETAILS,
  REQUEST_PRICE_RECORD_TYPES,
  RECEIVE_PRICE_RECORD_TYPES,
  RESET_PRICE_RECORD_TYPES
} from '../actions/costMaintenanceActions';
import {
  RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH,
  RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH
} from '../actions/errorDialogActions';

export default function reportSupplementaryDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.report.supplementaryData;
  }

  if (type === REQUEST_BUSINESS_CENTER_DETAILS) {
    return { ...state, businessCenters: { ...state.businessCenters, loading: true } };
  }

  if (type === RECEIVE_BUSINESS_CENTER_DETAILS) {
    return { ...state, businessCenters: { data: data.businessCenters, loading: false } };
  }

  if (type === RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH) {
    return { ...state, businessCenters: { ...state.businessCenters, loading: false } };
  }

  if (type === RESET_BUSINESS_CENTER_DETAILS) {
    return { ...state, businessCenters: { data: [], loading: false } };
  }

  if (type === REQUEST_PRICE_RECORD_TYPES) {
    return { ...state, priceRecordTypes: { data: [], loading: true } };
  }

  if (type === RECEIVE_PRICE_RECORD_TYPES) {
    return { ...state, priceRecordTypes: { data: data.priceRecordTypes, loading: false } };
  }

  if (type === RESET_PRICE_RECORD_TYPES || type === RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH) {
    return { ...state, priceRecordTypes: { data: [], loading: false } };
  }

  return state;
}
