/**
 * Created by hgun0888
 * 12/3/18
 * */

/**
 * Action Types
 */
export const HIDE_SUCCESS_DIALOG = 'HIDE_SUCCESS_DIALOG';

/**
 * Actions
 */
export const hideSuccessDialog = () => ({ type: HIDE_SUCCESS_DIALOG });
