/**
 * Created by aheshperera
 * 12/3/18
 * */

import initialState from './initialState';
import { RECEIVE_832_FILES } from '../actions/filesActions';

export default function bisFileReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.bisFiles;
  }

  if (type === RECEIVE_832_FILES) {
    return data;
  }
  return state;
}
