import initialState from './initialState';
import { RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT } from '../actions/filesActions';
import { HIDE_SUCCESS_DIALOG } from '../actions/successDialogActions';
import {
  RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER,
  RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER,
  RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER,
  RECEIVE_GROUP_PERSIST_STATUS,
  RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS,
  RECEIVE_SUPPLIER_PERSIST_STATUS,
  RECEIVE_SUPPLIER_UPDATE_STATUS,
  SUCCESS_UPDATE_SUPPLIER_CONTACT,
} from '../actions/userManagementActions';
import {
  RECEIVE_REPORT_TEMPLATE_DELETE_STATUS,
  RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS,
} from '../actions/reportsActions';
import {
  RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE,
  RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE,
  RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE
} from '../actions/workspaceActions';
import {
  RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR,
  RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR,
  RECEIVE_SAS_FD_FILE_IMPORT,
  RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM,
} from '../actions/agreementActions';

export default function successReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.success;
  }
  switch (type) {
    case RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT:
      return { show: true, message: 'Accepted to load prices' };

    case RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER:
    case RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER:
    case RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER:
    case RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS:
    case RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR:
    case RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM:
    case RECEIVE_SUPPLIER_UPDATE_STATUS:
      return { show: true, message: 'Updated Successfully' };

    case RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE:
      return { show: true, message: data.message };

    case RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE:
      return { show: true, message: `Support Request #${data.businessProcessId} has been submitted successfully` };

    case RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE:
      return { show: true, message: `Request #${data.businessProcessId} has been received` };

    case RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS:
    case RECEIVE_GROUP_PERSIST_STATUS:
    case RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR:
      return { show: true, message: 'Created Successfully', type: 'text' };

    case RECEIVE_REPORT_TEMPLATE_DELETE_STATUS:
      return { show: true, message: 'Deleted Successfully' };

    case RECEIVE_SAS_FD_FILE_IMPORT:
      return { show: true, message: 'File Accepted to Process' };

    case HIDE_SUCCESS_DIALOG:
      return { show: false, message: '' };

    case RECEIVE_SUPPLIER_PERSIST_STATUS:
      return { show: true, message: 'Supplier Profile Sucessfully Created', type: 'text' };
    case SUCCESS_UPDATE_SUPPLIER_CONTACT:
      return { show: true, message: 'Supplier Contact Details Successfully Updated', type: 'text' };
    default:
      return state;
  }
}
