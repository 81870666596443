import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../externals/workspaceProxy';
import {
  SUBMIT_SUPPLIER_SUPPORT_REQUEST,
  REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES,
  receiveSupplierSupportRequestSubmissionResponse,
  receiveSupplierSupportRequestTypes,
  receiveErrorInSupplierSupportRequestTypesFetch,
  REQUEST_ALL_HELP_TOPICS,
  REQUEST_HELP_TOPIC_CONTENT,
  receiveAllHelpTopics,
  receiveErrorInHelpTopicsFetch,
  receiveHelpTopicContent,
  REQUEST_RECENT_ACTIVITY_DATA,
  receiveRecentActivityData,
  receiveErrorInRecentActivityDataFetch,
  FETCH_REQUEST_DETAILS,
  receiveRequestDetails,
  SUBMIT_WORKSPACE_REQUEST,
  receiveWorkspaceRequestSubmissionResponse,
  UPDATE_SELECTED_REQUEST_STATUS,
  SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE,
  receiveWorkspaceRequestActionResponse,
  ADD_WORKSPACE_REQUEST_COMMENT,
  receiveWorkspaceSearchData,
  receiveErrorInWorkspaceSearchDataFetch,
  REQUEST_WORKSPACE_SEARCH_DATA
} from '../actions/workspaceActions';
import {
  receiveErrorInSupplierSupportRequestSubmission,
  receiveErrorInHelpTopicContentFetch,
  receiveErrorInRequestDetailsFetch,
  receiveErrorInWorkspaceRequestSubmission,
  receiveErrorInRequestStatusUpdate,
  receiveErrorInWorkspaceRequestActionChange,
  receiveErrorInWorkspaceRequestCommentSubmission
} from '../actions/errorDialogActions';
import {
  SUPPLIER_SUPPORT_REQUEST_SUBMISSION_ERROR,
  UNABLE_TO_FETCH_HELP_TOPICS,
  UNABLE_TO_FETCH_WORKSPACE_METADATA,
  UNABLE_TO_FETCH_HELP_TOPIC_CONTENT,
  UNABLE_TO_LOAD_REQUEST_DETAILS,
  WORKSPACE_REQUEST_SUBMISSION_ERROR,
  WORKSPACE_REQUEST_STATUS_UPDATE_ERROR,
  WORKSPACE_REQUEST_UPDATE_ERROR,
  WORKSPACE_REQUEST_COMMENT_SUBMISSION_ERROR,
  UNABLE_TO_FETCH_WORKSPACE_SEARCH_DATA
} from '../../helpers/messageConstant';
import { REQUEST_TYPES, FILE_TYPES, REQUEST_ACTION_TYPES } from '../../helpers/constants';
/**
 * Worker Saga: will be fired on Workspace related actions
 *
 * @author ysum5308
 */
export function* fetchAllSupplierSupportRequestTypes() {
  try {
    const supportRequestTypes = yield call(api.fetchAllSupplierSupportRequestTypes);
    yield put(receiveSupplierSupportRequestTypes(supportRequestTypes));
  } catch (e) {
    yield put(receiveErrorInSupplierSupportRequestTypesFetch(UNABLE_TO_FETCH_WORKSPACE_METADATA));
  }
}

export function* submitSupplierSupportRequest({ data }) {
  const params = { ...data };
  try {
    if (data.attachments.length > 0) {
      const attachments = yield call(api.uploadWorkspaceAttachments, data.requestType, data.attachments);
      params.attachments = attachments;
    }
    const responseData = yield call(api.submitWorkspaceRequest, params);
    yield put(receiveSupplierSupportRequestSubmissionResponse(responseData));
  } catch (e) {
    yield put(receiveErrorInSupplierSupportRequestSubmission(SUPPLIER_SUPPORT_REQUEST_SUBMISSION_ERROR));
  }
}

export function* fetchAllHelpTopics() {
  try {
    const helpTopics = yield call(api.fetchAllHelpTopics);
    yield put(receiveAllHelpTopics(helpTopics));
  } catch (e) {
    yield put(receiveErrorInHelpTopicsFetch(UNABLE_TO_FETCH_HELP_TOPICS));
  }
}

export function* fetchHelpTopicContent({ data }) {
  try {
    const selectedTopicDetails = yield call(api.fetchHelpTopicContent, data.topicId);
    yield put(receiveHelpTopicContent(selectedTopicDetails));
  } catch (e) {
    yield put(receiveErrorInHelpTopicContentFetch(UNABLE_TO_FETCH_HELP_TOPIC_CONTENT));
  }
}

export function* fetchRecentActivityData({ data }) {
  try {
    const recentActivityData = yield call(api.fetchRecentActivityData, data);
    yield put(receiveRecentActivityData(recentActivityData));
  } catch (e) {
    yield put(receiveErrorInRecentActivityDataFetch(UNABLE_TO_FETCH_WORKSPACE_METADATA));
  }
}

export function* fetchRequestDetails({ data }) {
  try {
    const selectedRequestData = yield call(api.fetchRequestDetails, data.requestId);
    yield put(receiveRequestDetails(selectedRequestData));
  } catch (e) {
    yield put(receiveErrorInRequestDetailsFetch(UNABLE_TO_LOAD_REQUEST_DETAILS));
  }
}

export function* submitWorkspaceRequest({ data }) {
  const params = { ...data };
  try {
    if (data.requestType === REQUEST_TYPES.VENDOR_COST_CATALOG) {
      const attachments = yield call(api.uploadWorkspaceAttachments, data.requestType, data.attachments);
      params.attachments = attachments;
    }
    const responseData = yield call(api.submitWorkspaceRequest, params);
    yield put(receiveWorkspaceRequestSubmissionResponse(responseData));
  } catch (e) {
    yield put(receiveErrorInWorkspaceRequestSubmission(WORKSPACE_REQUEST_SUBMISSION_ERROR));
  }
}

export function* updateRequestStatus({ data }) {
  try {
    yield call(api.updateRequestStatus, data);
    const updatedRequestData = yield call(api.fetchRequestDetails, data.requestId);
    yield put(receiveRequestDetails(updatedRequestData));
  } catch (e) {
    yield put(receiveErrorInRequestStatusUpdate(WORKSPACE_REQUEST_STATUS_UPDATE_ERROR));
  }
}

export function* submitRequestActionChange({ data }) {
  const { showConfirmationDialog, refreshRequestId, ...params } = data;
  try {
    if (data.action === REQUEST_ACTION_TYPES.COMMENT && data.attachments.length > 0) {
      const attachments = yield call(api.uploadWorkspaceAttachments, FILE_TYPES.GENERAL_WORKSPACE_ATTACHMENT, data.attachments);
      params.attachments = attachments;
    }
    const actionChangeResponse = yield call(api.submitRequestActionChange, params);

    const updatedRequestData = yield call(api.fetchRequestDetails, refreshRequestId);
    yield put(receiveRequestDetails({ ...updatedRequestData, isVariationReportGenerated: false }));

    if (showConfirmationDialog) {
      yield put(receiveWorkspaceRequestActionResponse(actionChangeResponse));
    } else if (actionChangeResponse.errorCode) {
      yield put(receiveErrorInWorkspaceRequestActionChange(actionChangeResponse.message));
    }
  } catch (e) {
    yield put(receiveErrorInWorkspaceRequestActionChange(WORKSPACE_REQUEST_UPDATE_ERROR));
  }
}

export function* addWorkspaceRequestComment({ data }) {
  const params = { ...data };
  try {
    if (data.attachments.length > 0) {
      const attachments = yield call(api.uploadWorkspaceAttachments, FILE_TYPES.GENERAL_WORKSPACE_ATTACHMENT, data.attachments);
      params.attachments = attachments;
    }
    yield call(api.addWorkspaceRequestComment, params);
    const updatedRequestData = yield call(api.fetchRequestDetails, data.businessProcessId);
    yield put(receiveRequestDetails(updatedRequestData));
  } catch (e) {
    yield put(receiveErrorInWorkspaceRequestCommentSubmission(WORKSPACE_REQUEST_COMMENT_SUBMISSION_ERROR));
  }
}

export function* fetchWorkspaceSearchData({ data }) {
  try {
    const workspaceSearchData = yield call(api.fetchWorkspaceSearchData, data);
    yield put(receiveWorkspaceSearchData(workspaceSearchData));
  } catch (e) {
    yield put(receiveErrorInWorkspaceSearchDataFetch(UNABLE_TO_FETCH_WORKSPACE_SEARCH_DATA));
  }
}

export function* workspaceSaga() {
  yield takeEvery(SUBMIT_SUPPLIER_SUPPORT_REQUEST, submitSupplierSupportRequest);
  yield takeEvery(REQUEST_SUPPLIER_SUPPORT_REQUEST_TYPES, fetchAllSupplierSupportRequestTypes);
  yield takeEvery(REQUEST_ALL_HELP_TOPICS, fetchAllHelpTopics);
  yield takeEvery(REQUEST_HELP_TOPIC_CONTENT, fetchHelpTopicContent);
  yield takeEvery(REQUEST_RECENT_ACTIVITY_DATA, fetchRecentActivityData);
  yield takeEvery(FETCH_REQUEST_DETAILS, fetchRequestDetails);
  yield takeEvery(SUBMIT_WORKSPACE_REQUEST, submitWorkspaceRequest);
  yield takeEvery(UPDATE_SELECTED_REQUEST_STATUS, updateRequestStatus);
  yield takeEvery(SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE, submitRequestActionChange);
  yield takeEvery(ADD_WORKSPACE_REQUEST_COMMENT, addWorkspaceRequestComment);
  yield takeEvery(REQUEST_WORKSPACE_SEARCH_DATA, fetchWorkspaceSearchData);
}
