/**
 * @author skit1169
 * */

/**
 * Action Types
 */
export const AUTH_COOKIE = 'AUTH_COOKIE';

/**
* Actions
*/
export const authCookie = (data) => ({ type: AUTH_COOKIE, data });
