import React, { Component } from 'react';
import {
  Card, CardBody, CardFooter, CardHeader, Button
} from 'reactstrap';
import { Modal, ModalBody } from 'mdbreact';
import logo from '../../assets/images/logo/sysco-logo-half-blue.png';
import '../../scss/splash-page.scss';

/**
 * Static Splash Page
 *
 */
export class SplashPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreDetails: false
    };
  }

  showModal = () => {
    this.setState({ showMoreDetails: true });
  };

  hideModal = () => {
    this.setState({ showMoreDetails: false });
  };

  render = () => (
    <div className="splash-wrapper">
      <div className="splash-panel" >
        <span className="ribbon"><span>COMING SOON</span></span>
        <img src={logo} alt="Sysco Cost Data Management" className="logo" />
        <div className="logoname">Cost Data Management</div>
        <div className="image-panel"></div>
        <div className="info-panel">
          <Modal className='faq-modal' isOpen={this.state.showMoreDetails} toggle={this.hideModal} size="lg">
            <ModalBody className="faq-modal-body" style={{ padding: '0px' }} >
              <Card>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h3 style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>Frequently Asked Questions (FAQ)</h3></CardHeader>
                <CardBody style={{ marginTop: '1rem', marginRight: '1rem' }}>
                  <ul style={{ textAlign: 'left', lineHeight: '18px' }}>
                    <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>What is Supplier Cost Management?</li>
                    <p>The Supplier Cost Management feature is a self-service, web-based application that allows our corporate billed
                      Suppliers to manage cost maintenance. Corporate billed Suppliers will have access to features for submitting cost updates,
                      accessing reports to view Sysco’s procurement cost, and capabilities to manage submissions for market influenced price
                      list changes with Sysco.</p>
                    <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>Why the change?</li>
                    <span>We want to provide an efficient and simple feature to help our valued partners execute cost management activities.
                      <br></br>Benefits include:
                      <ul className="dashed">
                      <li>&nbsp;Access to cost audit reports as needed with current, up to date information</li>
                      <li>&nbsp;Improve accuracy of data by submitting all cost updates directly, without manual hand-offs</li>
                      <li>&nbsp;Additional security protocols and increased layers of authentication to ensure proprietary data is protected</li>
                    </ul>
                    </span>
                    <br></br>
                    <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>When will this be released?</li>
                    <p>The application will launch Spring 2021</p>
                    <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>How will I receive training?</li>
                    <p>Training will be rolled out in waves and further details will follow soon.</p>
                    <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>Additional Support:</li>
                    <p>While the use of this self-service application will be required, the Costing Team will continue to maintain
                      valued relationships with our supplier partners and provide any necessary support.</p>
                  </ul>
                </CardBody>
                <CardFooter style={{ textAlign: 'center' }}>
                  <span className="iwt" style={{ margin: '1rem 0rem', display: 'block', fontSize: '16px' }}>
                    <i className="fa fa-envelope" ></i>
                    <span style={{ marginTop: '1rem', marginLeft: '0.5rem', fontWeight: 'bold' }}>Please send additional questions
                    to: suppliercost@sysco.com</span>
                  </span>
                </CardFooter>
              </Card>
            </ModalBody>
          </Modal>
          <Button type="button" className="details-btn"
            onClick={this.showModal}>I&apos;d like more details</Button>
        </div>
      </div>
    </div>
  );
}

export default SplashPage;
