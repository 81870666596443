/**
 * @author hgun0888
 * @author ysum5308
 * */
import initialState from './initialState';
import {
  SEARCH_VENDORS,
  SELECT_SUVC_ASSOCIATION,
  SELECT_VENDOR_ASSOCIATION,
  RESET_SELECTED_SUVCS
} from '../actions/vendorActions';
import stringMerge from '../../util/stringMerge';
import removeByKey from '../../util/removeByKey';

function getApplicableSuvcList(code, filterState) {
  return Object.keys(filterState.searchedSuvcList).includes(code) ? { ...filterState.searchedSuvcList } : { ...filterState.selectedSuvcs };
}

function getSelectedOrder(code, selectedSuvcArray) {
  return selectedSuvcArray.includes(code) ? [...selectedSuvcArray] : [...selectedSuvcArray, code];
}

function setVendorsAndSuvcCode(suvc, data, selectedVendors, arr, suvcObj, suvcMap) {
  let isVendorsMatched = false;
  if (suvc.vendors) {
    suvc.vendors.forEach((vendor) => {
      if (stringMerge(vendor.code, vendor.name, ' - ').includes(data.value.toUpperCase())) {
        isVendorsMatched = true;
        if (selectedVendors && selectedVendors.length > 0 && selectedVendors.includes(vendor.code)) {
          arr.push({ ...vendor, selected: true });
        } else {
          arr.push({ ...vendor });
        }
      }
    });
  }
  suvcObj.vendors = arr;
  if (isVendorsMatched) {
    suvcMap[suvc.code] = suvcObj;
  }
}

function mapSuvc(data, state, suvcMap) {
  data.suvcMappingList.forEach((suvc) => {
    const isSuvcSelected = state.selectedSuvcs && Object.keys(state.selectedSuvcs).length > 0
      && Object.keys(state.selectedSuvcs).includes(suvc.code);
    if (stringMerge(suvc.code, suvc.name, ' - ').includes(data.value.toUpperCase())) {
      if (isSuvcSelected) {
        suvcMap[suvc.code] = { ...state.selectedSuvcs[suvc.code] };
      } else {
        suvcMap[suvc.code] = { ...suvc };
      }
    } else {
      const arr = [];
      let selectedVendors;
      let suvcObj;
      if (isSuvcSelected) {
        suvcObj = { ...state.selectedSuvcs[suvc.code] };
        selectedVendors = suvcObj.vendors.filter((value) => value.selected === true).map((value) => value.code);
      } else {
        suvcObj = { ...suvc };
      }
      setVendorsAndSuvcCode(suvc, data, selectedVendors, arr, suvcObj, suvcMap);
    }
  });
}

function setVendorSelectionStatus(vendor, data) {
  if (vendor.code === data.row.code) {
    vendor.selected = data.value;
  } else {
    vendor.selected = false;
  }
}

function setSelectionStatus(suvcObj, state, data) {
  let isAllSelected = true;
  let atLeastOneSelected = false;
  if (suvcObj.vendors) {
    suvcObj.vendors.forEach((vendor) => {
      if (Object.keys(state.selectedSuvcs).includes(suvcObj.code)) {
        if (vendor.code === data.row.code) {
          vendor.selected = data.value;
        }
        isAllSelected = isAllSelected && vendor.selected;
        atLeastOneSelected = atLeastOneSelected || vendor.selected;
      } else {
        setVendorSelectionStatus(vendor, data);
        isAllSelected = isAllSelected && vendor.selected;
        atLeastOneSelected = atLeastOneSelected || vendor.selected;
      }
    });
  }
  suvcObj.selected = isAllSelected;
  suvcObj.minOneSelected = atLeastOneSelected;
}

export default function vendorFilterDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.vendor.filterData;
  }

  if (type === RESET_SELECTED_SUVCS) {
    return initialState.vendor.filterData;
  }

  if (type === SEARCH_VENDORS) {
    if (data.suvcMappingList && Object.keys(data.suvcMappingList).length > 0) {
      const suvcMap = {};
      mapSuvc(data, state, suvcMap);
      return {
        ...state,
        suvcList: suvcMap,
        selectedSuvcs: { ...state.selectedSuvcs },
        selectedOrder: [...state.selectedOrder],
        searchedSuvcList: suvcMap,
      };
    }
  }

  if (type === SELECT_SUVC_ASSOCIATION) {
    const suvcList = getApplicableSuvcList(data.row.code, state);
    const suvcObj = suvcList[data.row.code];
    suvcObj.vendors.forEach((vendor) => {
      vendor.selected = data.value;
    });
    suvcObj.selected = data.value;
    suvcObj.minOneSelected = data.value;
    let selectedSuvcs;
    let selectedOrder;
    if (suvcObj.selected) {
      selectedSuvcs = { ...state.selectedSuvcs, [suvcObj.code]: suvcObj };
      selectedOrder = getSelectedOrder(suvcObj.code, state.selectedOrder);
    } else {
      selectedSuvcs = removeByKey(state.selectedSuvcs, suvcObj.code);
      selectedOrder = state.selectedOrder.filter((code) => code !== suvcObj.code);
    }
    return {
      ...state, suvcList, selectedSuvcs, selectedOrder
    };
  }

  if (type === SELECT_VENDOR_ASSOCIATION) {
    const suvcList = getApplicableSuvcList(data.row.suvc.code, state);
    const suvcObj = suvcList[data.row.suvc.code];
    setSelectionStatus(suvcObj, state, data);
    let selectedSuvcs;
    let selectedOrder;
    if (suvcObj.minOneSelected) {
      selectedSuvcs = { ...state.selectedSuvcs, [suvcObj.code]: suvcObj };
      selectedOrder = getSelectedOrder(suvcObj.code, state.selectedOrder);
    } else {
      selectedSuvcs = removeByKey(state.selectedSuvcs, suvcObj.code);
      selectedOrder = state.selectedOrder.filter((code) => code !== suvcObj.code);
    }
    return {
      ...state, suvcList, selectedSuvcs, selectedOrder
    };
  }

  return state;
}
