/**
 * Created by ngan4172
 * */
import initialState from './initialState';
import {
  RECEIVE_VENDOR_BASES
} from '../actions/agreementActions';

export default function vendorBasesReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.vendorBases;
  }

  if (type === RECEIVE_VENDOR_BASES) {
    return data;
  }

  return state;
}
