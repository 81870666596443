import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardFooter, CardHeader, Button
} from 'reactstrap';
import { Modal, ModalBody, } from 'mdbreact';
import '../../scss/cookie-page.scss';
import QuickLink from '../Dashboard/components/quickLinksPanel/quickLink';
import {
  getBrowserType,
  browserCookieDetails,
  UNIDENTIFIED
} from '../../util/browserDetails';
import { URLS } from '../../helpers/constants';
/**
 * CookieSettingsPage
 * @author skit1169
 * */
export class CookieSettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browserName: getBrowserType(),
    };
  }

  hideModal = () => {
    this.props.hideCookieGuideModal();
  }

  getOptionalDisplayText = () => {
    if (window.location.hostname && URLS.CDM_PUBLIC_URL.includes(window.location.hostname)) {
      return <div style={{ marginRight: '7rem' }}>
      <p><b>Additionally, </b>
      The application will continue to work even if the third-party cookies are blocked (but not all the cookies are blocked).</p>
      </div>;
    }
    return <div style={{ marginRight: '7rem' }}>
      <p><b>Optionally</b> if you prefer to have third-party cookies blocked in the browser,
       please use the following URL to login to the application</p>
      <QuickLink text={URLS.CDM_PUBLIC_URL} url={URLS.CDM_PUBLIC_URL} divStyles={{ textAlign: 'left' }}/>
      </div>;
  }

  getContentWithFormat = (content) => {
    const details = <React.Fragment key={'index'}>
      {content.map((data, index) => (
        <div key={index}>
        <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
          {data.subHeading}
        </li>
        <p>{data.subText} {data.icon && <i className={data.icon} aria-hidden='true'> click on it.</i>}</p>
        &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      ))
      } {this.getOptionalDisplayText()}
    </React.Fragment>;

    return <CardBody style={{ marginTop: '1rem', marginRight: '1rem' }}>
      <ul style={{ textAlign: 'left', lineHeight: '18px' }}>
        {details}
      </ul>
    </CardBody>;
  }

  getDefaultContent = () => (
    <CardBody style={{ marginTop: '1rem', marginRight: '1rem' }}>
      <ul style={{ textAlign: 'left', lineHeight: '18px' }}>
        <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
          Sorry, the cookie enabling guide for this browser type is not available with us!
        </li>
        <p >But, don&apos;t worry, you can still search for how to enable cookies with this type of browser.</p>
        <p >Therefore, open a new tab in your browser, then search by typing &quot;how to enable cookies in browser&quot;
          (give the name of your browser).</p>
        <p >And follow the steps where you can allow all cookies (make sure the third-party cookies are also enabled).</p>
        <li style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
          Or else, please use one of the following browsers so you can get the best experience with the CDM dashboard application.
        </li>
        <span>
          <br />
          <ul className='dashed'>
            <li>&nbsp;Chrome</li>
            <li>&nbsp;Firefox</li>
            <li>&nbsp;Microsoft Edge</li>
            <li>&nbsp;Safari</li>
          </ul>
        </span>
        <br />
        <br />
      </ul>
    </CardBody>);

  render() {
    const { browserName } = this.state;
    const isBrowserUnKnown = browserName === UNIDENTIFIED;
    return (
      <div className='cookie-wrapper'>
        <Modal id={this.props.id} className='faq-modal' isOpen={this.props.isOpen} size='lg' fade={false} toggle={this.hideModal}>
          <ModalBody className='faq-modal-body' style={{ padding: '0px' }} >
            <Card style={{
              minHeight: 'calc(70vh - 5px)',
              borderColor: '#044a76'
            }}>
              <CardHeader style={{ textAlign: 'center', border: 0, height: '4.5rem' }}>
                <h3 style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
                  How To Enable My Browser&apos;s Cookies {isBrowserUnKnown ? '' : `(${browserName})`}
                  <div style={{
                    fontSize: '10px',
                    marginLeft: '46rem',
                    marginTop: '-4.5rem',
                    opacity: 1,
                    display: 'block',
                    zIndex: '100000',
                  }}>
                  <Button
                    id='close_browser_cookie_guide'
                    color='white'
                    type='button'
                    title='close'
                    style={{ color: '#ebf5fb' }}
                    onClick={this.hideModal}
                  ><i className='fa fa-times-circle-o fa-3x' aria-hidden='true'></i>
                  </Button>
                  </div>
                </h3>
              </CardHeader>
              {isBrowserUnKnown
                ? this.getDefaultContent() : this.getContentWithFormat(browserCookieDetails[browserName])}
              <CardFooter style={{ textAlign: 'center' }}>
                <span className='iwt' style={{ margin: '1rem 0rem', display: 'block', fontSize: '16px' }}>
                  <i className='fa fa-envelope' ></i>
                  <span style={{ marginTop: '1rem', marginLeft: '0.5rem', fontWeight: 'bold' }}>Please send additional questions
                    to: suppliercost@sysco.com</span>
                </span>
              </CardFooter>
            </Card>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CookieSettingsPage.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hideCookieGuideModal: PropTypes.func,
};

export default CookieSettingsPage;
