import axios from 'axios';
import BFFProxy from './bffProxy';
import axiosWithPayloadInterceptor from '../interceptor/axiosWithPayloadInterceptor';
import {
  HTTP_METHODS, HTTP_STATUS, URLS, FILE_TYPES
} from '../helpers/constants';
import { S3_FILE_UPLOAD_ERROR } from '../helpers/messageConstant';
/**
 * Fetch data from APIs related to Cost Maintenance
 *
 * @author ysum5308
 */
class CostMaintenanceProxy {
  static async validateItemsBySupcs(data) {
    const config = {
      url: `${URLS.COST_DATA_URL}/items/validate`,
      params: {
        items: data.items.join(','),
        vendors: data.vendors.join(','),
        houseTypes: data.houseTypes.join(','),
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchCostDataRecords(data) {
    const url = `${URLS.COST_DATA_URL}/cost-import-records`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        vendors: data.vendors.join(','),
        houseTypes: data.houseTypes.join(','),
        items: data.items.join(','),
        sourceIndicators: data.sourceIndicators.join(','),
        categoryManagedIndicators: data.categoryManagedIndicators.join(','),
        houseCodes: data.houses.join(','),
        generateNewCostRecords: data.houses.length > 0
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchBusinessCenters(suvcs) {
    const config = {
      url: `${URLS.MASTER_DATA_URL}/business-centers`,
      params: {
        suvcs: suvcs.join(',')
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchPriceRecordTypes(data) {
    const config = {
      url: `${URLS.MASTER_DATA_URL}/price-record-types`,
      params: {
        recordType: data.recordType,
        vendors: data.vendors.join(','),
        businessCenters: data.businessCenters.join(','),
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async submitVendorPriceImportFiles(data) {
    const promises = [];
    const { houseType, costChangeDrivers } = data;
    data.submittedFiles.forEach((file) => {
      promises.push(BFFProxy.importMultipleAdhocCostFiles({ file, houseType, costChangeDriver: costChangeDrivers[file.name] }));
    });
    return Promise.all(promises);
  }

  static async importAdhocCostFile(params) {
    const { file, houseType } = params;
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(FILE_TYPES.ADHOC_COST_FILE, file.name);
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        const config = {
          url: `${URLS.COST_DATA_URL}/import/adhoc-cost-file`,
          params: {
            houseType,
            filename: encodeURIComponent(signedUrlResponse.data.fileName),
          },
          method: HTTP_METHODS.GET
        };
        const response = await axiosWithPayloadInterceptor(config);
        return { data: response.data, fileName: signedUrlResponse.data.fileName };
      }
      return Promise.reject(new Error(S3_FILE_UPLOAD_ERROR));
    } catch (e) {
      return Promise.reject(new Error(e.message));
    }
  }

  static async submitGenerateCostReports(data) {
    const config = {
      url: `${URLS.COST_DATA_URL}/calculate`,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async fetchFileTransferDetails(serverType, userGroups) {
    const spanDays = 10;
    const config = {
      url: `${URLS.FILE_TRANSFER_DETAILS_URL}`,
      params: {
        server_type: serverType,
        span: spanDays,
        groups: userGroups.join(',')
      },
      method: HTTP_METHODS.GET
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }
}

export default CostMaintenanceProxy;
