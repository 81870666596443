import axios from 'axios';
import { stringify } from 'qs';
import BFFProxy from '../externals/bffProxy';
import { TRANSFORM_HTTP_MESSAGE, MAX_PAYLOAD_SIZE_IN_BYTES, } from '../helpers/constants';
import { responseStatusValidator } from '../util/validator';

const axiosWithPayloadInterceptor = axios.create();
axiosWithPayloadInterceptor.defaults.withCredentials = true;
axiosWithPayloadInterceptor.defaults.validateStatus = (status) => responseStatusValidator(status);
axiosWithPayloadInterceptor.defaults.paramsSerializer = (params) => stringify(params);

// Check if payload size exceeds 0.9 MB
const isPayloadBulky = (payload) => MAX_PAYLOAD_SIZE_IN_BYTES < Buffer.byteLength(JSON.stringify(payload));

const isPayloadModificationAllowed = (transformResponseHeader, resourceUrlHeader) => transformResponseHeader && (transformResponseHeader === TRANSFORM_HTTP_MESSAGE.ALLOW)
  && resourceUrlHeader;

axiosWithPayloadInterceptor.interceptors.request.use(
  async (request) => {
    const payload = request.data;
    if (payload && Object.keys(payload).length > 0) {
      if (isPayloadBulky(payload)) {
        const resourceName = await BFFProxy.uploadObject(payload);
        request.headers['transform-request'] = TRANSFORM_HTTP_MESSAGE.ALLOW;
        request.headers['resource-name'] = resourceName;
        request.data = {};
      }
    }
    return request;
  }, (error) => { throw error; }
);

axiosWithPayloadInterceptor.interceptors.response.use(
  async (response) => {
    const transformResponseHeader = response.headers['transform-response'];
    const resourceUrlHeader = response.headers['resource-url'];
    if (isPayloadModificationAllowed(transformResponseHeader, resourceUrlHeader)) {
      const payload = await BFFProxy.downloadCompressedObject(resourceUrlHeader);
      response.data = JSON.parse(payload);
    }
    return response;
  }, (error) => { throw error; }
);

export default axiosWithPayloadInterceptor;
