import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to vendors
 *
 * @author hgun0888
 */

class VendorProxy {
  static async fetchAllVendors() {
    const url = `${URLS.VENDORS_FETCH_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchAllOpcos() {
    const url = `${URLS.MASTER_DATA_URL}/houses`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        sort_column: 'co_3_oc',
        sort_order: 'ASC',
      }
    };
    const response = await axios(config);
    return response.data;
  }
}

export default VendorProxy;
