import React, { Component } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Button } from 'mdbreact';
import CookiePolicy from './cookiePolicy';

/**
 * CookieConsentBanner
 * @author skit1169
 * */
class CookieConsentBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookiePolicyModel: false,
      env: process.env.NODE_ENV,
    };
  }

  hideCookiePolicyModel = () => {
    this.setState({ showCookiePolicyModel: false });
  }

  showCookiePolicyModel = () => {
    this.setState({ showCookiePolicyModel: true });
  };

  render() {
    return (
      <React.Fragment>
        <CookieConsent
          disableStyles={false}
          location='bottom'
          buttonClasses="btn btn-primary"
          containerClasses="alert alert-warning col-lg-12"
          contentClasses="text-capitalize"
          cookieName={`CDMLoginCookie-${this.state.env}`}
          style={{
            width: '25vw',
            height: '15vw',
            zIndex: 5050,
            marginLeft: '72%',
            marginBottom: '1.8rem',
            borderRadius: '23px',
            borderColor: 'rgb(53, 53, 53)',
            minWidth: '350px',
            minHeight: '270px',
          }}
          buttonStyle={{
            marginTop: '-1.1vw',
            width: '21vw',
            borderRadius: '4px',
            background: 'rgb(254 205 129)',
          }}
          onAccept={this.hideCookiePolicyModel}
          hideOnAccept={navigator.cookieEnabled && Cookies.get(`CDMLoginCookie-${this.state.env}`)}
        >
          <div style={{ width: '21vw', align: 'center', marginTop: '-15px' }}>
            <span role="img" aria-label="cookie-emoji" style={{ fontSize: '3vw' }}> 🍪</span>
            <p>This website uses cookies for authentication purposes. Please enable cookies for successful login and proper website functionality. </p>
            <Button type='button' color='primary'
              style={{
                marginTop: '-2%',
                width: '21vw',
                marginBottom: '1vh',
              }}
              onClick={this.showCookiePolicyModel}
            >
              More Info
            </Button>
          </div>
        </CookieConsent>
        <CookiePolicy
          id='cookie-policy'
          isOpen={this.state.showCookiePolicyModel}
          hideModal={this.hideCookiePolicyModel}
        />
      </React.Fragment>
    );
  }
}

export default CookieConsentBanner;
