/**
 * Created by hgun0888
 * */

import initialState from './initialState';
import {
  RECEIVE_REPORT_METADATA, RECEIVE_REPORT_TEMPLATE_DELETE_STATUS,
  RECEIVE_REPORT_TEMPLATE_DETAILS, RESET_SELECTED_REPORT_TEMPLATE_DETAILS,
  RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS, REQUEST_REPORT_METADATA
} from '../actions/reportsActions';
import { REPORT_TYPES } from '../../helpers/constants';
import { RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH } from '../actions/errorDialogActions';

export default function costAuditDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.report.costAuditData;
  }

  if (type === REQUEST_REPORT_METADATA) {
    return { ...state, metadata: { ...state.metadata, loading: true } };
  }

  if (type === RECEIVE_REPORT_TEMPLATE_DETAILS) {
    return { ...state, selection: { template: data } };
  }

  if (type === RESET_SELECTED_REPORT_TEMPLATE_DETAILS) {
    return { ...state, selection: { ...initialState.report.costAuditData.selection } };
  }

  if (type === RECEIVE_REPORT_TEMPLATE_PERSIST_STATUS) {
    return {
      ...state,
      metadata: { ...state.metadata, templates: data.templates },
      selection: { template: data.selectedTemplate }
    };
  }

  if (type === RECEIVE_REPORT_TEMPLATE_DELETE_STATUS) {
    return {
      ...state,
      metadata: { ...state.metadata, templates: data.data.templates },
      selection: { template: { templateHeader: { columns: [] } } }

    };
  }

  if (type === RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH) {
    return { ...state, metadata: { ...state.metadata, loading: false } };
  }

  if (type === RECEIVE_REPORT_METADATA) {
    if (data && data.reportType === REPORT_TYPES.COST_AUDIT_GRID) {
      const columnsMap = {};
      data.columns.forEach((column) => {
        columnsMap[column.name] = column.displayName;
      });

      return {
        ...state,
        loading: false,
        metadata: {
          ...data,
          columnsMap
        }
      };
    }
  }
  return state;
}
