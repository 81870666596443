import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import FileSaver from 'file-saver';
import {
  FILE_TYPES,
  HTTP_CONTENT_TYPE,
  HTTP_METHODS,
  HTTP_STATUS,
  PROCESS_STATUS_TYPES,
  URLS,
} from '../helpers/constants';
import {
  S3_FILE_UPLOAD_ERROR,
  S3_FILE_DOWNLOAD_ERROR,
} from '../helpers/messageConstant';

const zlib = require('zlib');

/**
 * Fetch data from APIs
 *
 * @author aheshperera
 */
class BFFProxy {
  static async fetch832Files() {
    const config = {
      url: URLS.BIS_FILES_URL,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchVendorPriceFilesImportLog(
    {
      username,
      suvc,
      fileName,
      fileType,
      startTime,
      endTime,
      businessProcessId,
      page, pageSize, sorted, userGroups
    }
  ) {
    const sortOrder = sorted.desc ? 'DESC' : 'ASC';
    const url = `${URLS.VENDOR_PRICE_FILES_IMPORT_LOG_URL}`;
    const config = {
      url,
      params: {
        username,
        suvc,
        file_name: fileName,
        file_type: fileType,
        business_process_id: businessProcessId,
        start_time: startTime,
        end_time: endTime,
        page,
        page_size: pageSize,
        sort_by: sorted.id,
        sort_order: sortOrder,
        groups: userGroups.join(',')
      },
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async downloadFiles(filePaths) {
    return axios({
      url: `${URLS.FILES_DOWNLOAD_URL}`,
      data: {
        filePaths
      },
      headers: {
        Accept: HTTP_CONTENT_TYPE.APPLICATION_OCTET_STREAM
      },
      method: 'POST',
      responseType: 'arraybuffer',
    }).then((response) => {
      const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data)));
      return axios({
        url: data.url,
        method: 'GET',
        responseType: 'arraybuffer',
      }).then((signedUrlResponse) => {
        const blob = new Blob([signedUrlResponse.data], { type: HTTP_CONTENT_TYPE.APPLICATION_OCTET_STREAM });
        return FileSaver.saveAs(blob, 'files.zip');
      });
    });
  }

  static async getVendorFileProcessStatus(processIds) {
    const config = {
      url: `${URLS.VENDOR_FILE_IMPORT_URL}/processes?id=${processIds.join(',')}`,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async submitVendorPriceReimportUsingVariationReport(variationDetails) {
    const url = `${URLS.VENDOR_FILE_IMPORT_URL}/load-variation-price`;
    const response = await axios.post(url, variationDetails);
    return response.data;
  }

  static async submitVendorPriceImportFiles(serverType, action, files, isOwnedItemsIncluded) {
    const promises = [];
    files.forEach((file) => {
      promises.push(BFFProxy.importVendorFile(action, file, serverType, isOwnedItemsIncluded));
    });
    return Promise.all(promises);
  }

  static async importMultipleAdhocCostFiles(params) {
    const { file, houseType, costChangeDriver } = params;
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(FILE_TYPES.ADHOC_COST_FILE_MASS_UPLOAD, file.name);
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);

      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        const processDataResponse = await axios.post(
          `${URLS.VENDOR_FILE_IMPORT_URL}/import/multi-adhoc-cost-files`,
          {
            actualFileName: signedUrlResponse.data.fileName,
            houseType,
            costChangeDriver
          }
        );
        return {
          fileName: file.name,
          url: s3UploadResponse.config.url,
          processId: processDataResponse.data.processId,
          timeStamp: processDataResponse.data.completionTimeStamp,
          status: processDataResponse.data.status,
          houseType
        };
      }
      return Promise.resolve({
        fileName: file.name,
        processId: '',
        status: PROCESS_STATUS_TYPES.ERROR,
        message: S3_FILE_UPLOAD_ERROR,
        houseType
      });
    } catch (e) {
      return Promise.resolve({
        fileName: file.name,
        processId: '',
        status: PROCESS_STATUS_TYPES.ERROR,
        message: e.message,
        houseType
      });
    }
  }

  static async importVendorFile(type, file, serverType, owned) {
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(FILE_TYPES.VENDOR_PRICE_FILE, file.name, { serverType });
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        const processDataResponse = await axios.post(URLS.VENDOR_FILE_IMPORT_URL, {
          filePath: `${signedUrlResponse.data.path}/${signedUrlResponse.data.fileName}`,
          type,
          serverType,
          owned,
          actualFileName: file.name
        });

        return {
          fileName: file.name,
          processId: processDataResponse.data.processId,
          status: processDataResponse.data.status,
          completionTimeStamp: processDataResponse.data.completionTimeStamp,
          type,
          owned,
          serverType
        };
      }
      return Promise.resolve({
        fileName: file.name,
        processId: '',
        status: PROCESS_STATUS_TYPES.ERROR,
        message: S3_FILE_UPLOAD_ERROR,
        type,
        owned,
        serverType
      });
    } catch (e) {
      return Promise.resolve({
        fileName: file.name,
        processId: '',
        status: PROCESS_STATUS_TYPES.ERROR,
        message: e.message,
        type,
        owned,
        serverType
      });
    }
  }

  static async getSignedUrlToUpload(type, fileName, data) {
    return axios.post(URLS.UPLOAD_SIGNED_URL_GENERATION_URL, {
      fileName,
      fileType: type,
      data
    });
  }

  static async uploadWithSignedUrl(signedUrl, file, fileType) {
    const config = {
      headers: { 'Content-Type': fileType },
    };
    return axios.put(signedUrl, file, config);
  }

  static async importFile(type, file) {
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(type, file.name);
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        const payload = {
          filePath: `${signedUrlResponse.data.path}/${signedUrlResponse.data.fileName}`,
          actualFileName: file.name
        };

        let url;
        if (type === FILE_TYPES.VENDOR_ASSOCIATION_FILE_IMPORT_REQUEST) {
          url = URLS.USER_VENDORS_ASSOCIATION_FILE_IMPORT_URL;
        } else {
          url = URLS.AGREEMENT_FILE_IMPORT_URL;
          payload.type = type;
        }

        const processDataResponse = await axios.post(url, payload);

        return {
          fileName: file.name,
          processId: processDataResponse.data.processId,
          status: processDataResponse.data.status,
          completionTimeStamp: processDataResponse.data.completionTimeStamp,
          type,
        };
      }
      return Promise.resolve({
        fileName: file.name,
        processId: '',
        status: PROCESS_STATUS_TYPES.ERROR,
        message: S3_FILE_UPLOAD_ERROR,
        type,
      });
    } catch (e) {
      return Promise.resolve({
        fileName: file.name,
        processId: '',
        status: PROCESS_STATUS_TYPES.ERROR,
        message: e.message,
        type,
      });
    }
  }

  static async submitImportFiles(type, files) {
    const promises = [];
    files.forEach((file) => {
      promises.push(BFFProxy.importFile(type, file));
    });
    return Promise.all(promises);
  }

  static handleError(errorMessage) {
    return Promise.reject(new Error(errorMessage));
  }

  static async uploadObject(object) {
    const resourceId = uuidv4().toString();
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(FILE_TYPES.TEMP_WORKSPACE_DATA_OBJECT, resourceId);
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, object, HTTP_CONTENT_TYPE.APPLICATION_JSON);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        return signedUrlResponse.data.fileName;
      }
      return this.handleError(S3_FILE_UPLOAD_ERROR);
    } catch (e) {
      return this.handleError(e.message);
    }
  }

  static async downloadCompressedObject(signedUrl) {
    try {
      const response = await axios({
        url: signedUrl,
        method: 'GET',
        responseType: 'arraybuffer',
      });
      if (response.status === HTTP_STATUS.OK) {
        const uint8arrayTypeList = zlib.unzipSync(Buffer.from(response.data, 'base64'));
        return new TextDecoder().decode(uint8arrayTypeList);
      }
      return this.handleError(S3_FILE_DOWNLOAD_ERROR);
    } catch (e) {
      return this.handleError(e.message);
    }
  }
}

export default BFFProxy;
