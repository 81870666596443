/**
 * @author fris4874
 * */

/**
 * Action Types
 */
export const FETCH_EARNED_INCOME_MAPPINGS = 'FETCH_EARNED_INCOME_MAPPINGS';
export const RECEIVE_EARNED_INCOME_MAPPINGS = 'RECEIVE_EARNED_INCOME_MAPPINGS';

export const RESET_EARNED_INCOME_MAPPINGS = 'RESET_EARNED_INCOME_MAPPINGS';

/**
 * Actions
 */
export const fetchEarnedIncomeMappings = (serverType, businessCenter) => (
  {
    type: FETCH_EARNED_INCOME_MAPPINGS,
    data: { serverType, businessCenter }
  });
export const receiveEarnedIncomeMappings = (data) => ({ type: RECEIVE_EARNED_INCOME_MAPPINGS, data });

export const resetEarnedIncomeMappings = () => (
  {
    type: RESET_EARNED_INCOME_MAPPINGS
  });
