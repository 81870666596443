/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * React Modules
 */
import {
  call, put, takeEvery, takeLatest
} from 'redux-saga/effects';
/**
 * Custom Modules
 */
import usersApi from '../../externals/usersProxy';
import userGroupApi from '../../externals/userGroupsProxy';
import {
  UNABLE_TO_LOAD_USER_DETAILS,
  UNABLE_TO_LOAD_USERS,
  UNABLE_TO_LOAD_USER_GROUP_ASSOCIATIONS,
  UNABLE_TO_LOAD_USER_GROUPS,
  UNABLE_TO_SAVE_DETAILS,
  UNABLE_TO_LOAD_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_DETAILS,
  UNABLE_TO_LOAD_SUPPLIER_ONBOARD_DETAILS,
  UNABLE_TO_UPDATE_SUPPLIER_CONTACT,
} from '../../helpers/messageConstant';
import {
  FETCH_USERS,
  receiveUsers,
  FETCH_ALL_USER_GROUPS,
  receiveAllUserGroups,
  receiveUserProfileDetails,
  REQUEST_USER_PROFILE_DETAILS,
  REQUEST_USER_GROUP_ASSOCIATION_DETAILS,
  requestUserGroupAssociationDetails,
  receiveUserGroupAssociationDetails,
  REQUEST_USER_PROFILE_MANAGEMENT_DETAILS,
  receiveUserProfileManagementDetails,
  receiveUserGroupDetails,
  SAVE_SUPERVISOR_FOR_USER,
  receiveSupervisorPersistStatus,
  SAVE_VENDORS_ASSOCIATION_FOR_USER,
  receiveVendorAssociationsPersistStatus,
  receiveDirectUserGroupAssociationsPersistStatus,
  REMOVE_SUPERVISOR_OF_USER,
  receiveSupervisorRemoveStatus, receiveUserProfileDetailsOfSelectedUser,
  REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER,
  REQUEST_USER_GROUP_DETAILS,
  SAVE_GROUP,
  receiveGroupPersistStatus,
  SAVE_DIRECT_USER_GROUP_ASSOCIATION,
  receiveSupplierPersistStatus,
  receiveSupplierUpdatetStatus,
  SAVE_SUPPLIER,
  UPDATE_SUPPLIER,
  REQUEST_SUPPLIER_ONBOARD_DETAILS,
  receiveSupplierOnboardDetails,
  REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS,
  receiveUserAssociationAndBusinessCenterContacts,
  TRIGGER_UPDATE_SUPPLIER_CONTACT,
  successUpdateSupplierContact
} from '../actions/userManagementActions';
import {
  receiveErrorInUserProfileDetailsFetch,
  receiveErrorInUserGroupAssociationDetailsFetch,
  receiveErrorInUserProfileDetailsFetchOfSelectedUser,
  receiveErrorInUserGroupDetailsFetch,
  receiveErrorInUsersFetch,
  receiveErrorInAllUserGroupsFetch,
  receiveErrorWhenSavingUserManagementDetails,
  receiveErrorInSupplierOnboardDetailsFetch,
  receiveErrorInUserAssociationAndBusinessCenterContactsFetch,
  receiveErrorUpdateSupplierContact
} from '../actions/errorDialogActions';
import { fetchBusinessCenters } from './costMaintenanceSaga';

/**
 * Worker Saga: will be fired on REQUEST_USER_PROFILE_DETAILS action
 */
export function* getUserProfileDetails({ data }) {
  try {
    const files = yield call(usersApi.fetchUserProfileDetails, data.networkId);
    yield put(receiveUserProfileDetails(files));
  } catch (e) {
    yield put(receiveErrorInUserProfileDetailsFetch(UNABLE_TO_LOAD_USER_DETAILS));
  }
}

export function* getUserGroupAssociationDetails({ data }) {
  try {
    const groups = yield call(userGroupApi.getUserGroupAssociationDetails, data.networkId);
    yield put(receiveUserGroupAssociationDetails(groups));
  } catch (e) {
    yield put(receiveErrorInUserGroupAssociationDetailsFetch(UNABLE_TO_LOAD_USER_GROUP_ASSOCIATIONS));
  }
}

export function* getUserProfileDetailsOfSelectedUser({ data }) {
  try {
    const files = yield call(usersApi.fetchUserProfileDetails, data.networkId);
    yield put(receiveUserProfileDetailsOfSelectedUser(files));
  } catch (e) {
    yield put(receiveErrorInUserProfileDetailsFetchOfSelectedUser(UNABLE_TO_LOAD_USER_DETAILS));
  }
}

export function* getUserProfileManagementDetails({ data }) {
  const { networkId, suvcs } = data;
  try {
    const userProfileManagementDetails = yield call(usersApi.fetchUserProfileManagementDetails, networkId);
    yield put(receiveUserProfileManagementDetails({ ...userProfileManagementDetails, suvcs }));
  } catch (e) {
    yield put(receiveErrorInUserProfileDetailsFetch(UNABLE_TO_LOAD_USER_DETAILS));
  }
}

export function* getUserGroupDetails({ data }) {
  const { networkId, groups } = data;
  try {
    const directUserGroupAssociationDetails = yield call(userGroupApi.getUserGroups, networkId);
    yield put(receiveUserGroupDetails({ directUserGroupAssociationDetails, groups }));
  } catch (e) {
    yield put(receiveErrorInUserGroupDetailsFetch(UNABLE_TO_LOAD_USER_GROUPS));
  }
}

export function* fetchUsers({ data }) {
  try {
    const allUsers = yield call(usersApi.getUsers, data);
    yield put(receiveUsers(allUsers));
  } catch (e) {
    yield put(receiveErrorInUsersFetch(UNABLE_TO_LOAD_USERS));
  }
}

export function* fetchAllUserGroups() {
  try {
    const allUserGroups = yield call(userGroupApi.getAllUserGroups);
    yield put(receiveAllUserGroups(allUserGroups));
  } catch (e) {
    yield put(receiveErrorInAllUserGroupsFetch(UNABLE_TO_LOAD_USER_GROUPS));
  }
}

export function* saveSupervisorForUser({ data }) {
  try {
    const response = yield call(usersApi.updateSupervisor, data);
    yield put(receiveSupervisorPersistStatus(response));
  } catch (e) {
    yield put(receiveErrorWhenSavingUserManagementDetails(UNABLE_TO_SAVE_DETAILS));
  }
}

export function* removeSupervisorForUser({ data }) {
  try {
    const response = yield call(usersApi.updateSupervisor, data);
    yield put(receiveSupervisorRemoveStatus(response));
  } catch (e) {
    yield put(receiveErrorWhenSavingUserManagementDetails(UNABLE_TO_SAVE_DETAILS));
  }
}

export function* saveAssociatedVendorsForUser({ data }) {
  try {
    const response = yield call(usersApi.updateVendorAssociations, data);
    yield put(receiveVendorAssociationsPersistStatus(response));
  } catch (e) {
    yield put(receiveErrorWhenSavingUserManagementDetails(UNABLE_TO_SAVE_DETAILS));
  }
}

export function* saveDirectUserGroupAssociations({ data }) {
  const { isSelfAssignment, directUserGroupAssociations } = data;
  try {
    const response = yield call(usersApi.updateGroupAssociations, directUserGroupAssociations);
    if (isSelfAssignment) {
      yield put(requestUserGroupAssociationDetails(directUserGroupAssociations.networkId));
    }
    yield put(receiveDirectUserGroupAssociationsPersistStatus(response));
  } catch (e) {
    yield put(receiveErrorWhenSavingUserManagementDetails(UNABLE_TO_SAVE_DETAILS));
  }
}

export function* saveGroup({ data }) {
  try {
    const group = yield call(userGroupApi.createUserGroup, data);
    yield put(receiveGroupPersistStatus({ parentGroupCode: data.parentGroupCode, group }));
  } catch (e) {
    const errorMessage = e.response.data ? e.response.data : UNABLE_TO_SAVE_DETAILS;
    yield put(receiveErrorWhenSavingUserManagementDetails(errorMessage));
  }
}

export function* saveSupplier({ data }) {
  try {
    const response = yield call(usersApi.createSupplier, data);
    yield put(receiveSupplierPersistStatus(response));
  } catch (e) {
    const errorMessage = e.response?.data ? e.response?.data : UNABLE_TO_SAVE_DETAILS;
    yield put(receiveErrorWhenSavingUserManagementDetails(errorMessage));
  }
}

export function* updateSupplier({ data }) {
  try {
    yield call(usersApi.updateSupplier, data);
    yield put(receiveSupplierUpdatetStatus());
  } catch (e) {
    const errorMessage = e.response?.data ? e.response?.data : UNABLE_TO_SAVE_DETAILS;
    yield put(receiveErrorWhenSavingUserManagementDetails(errorMessage));
  }
}

export function* requestUserAssociationAndBusinessCenterContacts({ data }) {
  try {
    yield call(fetchBusinessCenters, { data: { suvcs: [data.suvc] } });
    const response = yield call(usersApi.fetchUserAssociationAndBusinessCenterContacts, data.suvc);
    yield put(receiveUserAssociationAndBusinessCenterContacts(response));
  } catch (e) {
    yield put(receiveErrorInUserAssociationAndBusinessCenterContactsFetch(UNABLE_TO_LOAD_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_DETAILS));
  }
}

export function* requestSupplierOnboardDetails({ data }) {
  try {
    const response = yield call(usersApi.fetchSupplierOnboardDetails, data.userId);
    yield put(receiveSupplierOnboardDetails(response));
  } catch (e) {
    yield put(receiveErrorInSupplierOnboardDetailsFetch(UNABLE_TO_LOAD_SUPPLIER_ONBOARD_DETAILS));
  }
}

export function* updateSupplierContact({ data }) {
  try {
    yield call(usersApi.updateSupplierContact, data);
    yield put(successUpdateSupplierContact());
  } catch (e) {
    const errorMessage = e.response?.data ? e.response?.data : UNABLE_TO_UPDATE_SUPPLIER_CONTACT;
    yield put(receiveErrorUpdateSupplierContact(errorMessage));
  }
}

/**
 * Starts fetching user profile information on each dispatched `REQUEST_USER_PROFILE_DETAILS` action.
 */
export function* userManagementSaga() {
  yield takeEvery(REQUEST_USER_PROFILE_DETAILS, getUserProfileDetails);
  yield takeEvery(REQUEST_USER_GROUP_ASSOCIATION_DETAILS, getUserGroupAssociationDetails);
  yield takeEvery(REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER, getUserProfileDetailsOfSelectedUser);
  yield takeEvery(REQUEST_USER_PROFILE_MANAGEMENT_DETAILS, getUserProfileManagementDetails);
  yield takeEvery(FETCH_USERS, fetchUsers);
  yield takeEvery(FETCH_ALL_USER_GROUPS, fetchAllUserGroups);
  yield takeEvery(SAVE_SUPERVISOR_FOR_USER, saveSupervisorForUser);
  yield takeEvery(REMOVE_SUPERVISOR_OF_USER, removeSupervisorForUser);
  yield takeEvery(SAVE_VENDORS_ASSOCIATION_FOR_USER, saveAssociatedVendorsForUser);
  yield takeEvery(SAVE_GROUP, saveGroup);
  yield takeEvery(SAVE_DIRECT_USER_GROUP_ASSOCIATION, saveDirectUserGroupAssociations);
  yield takeEvery(REQUEST_USER_GROUP_DETAILS, getUserGroupDetails);
  yield takeEvery(SAVE_SUPPLIER, saveSupplier);
  yield takeEvery(UPDATE_SUPPLIER, updateSupplier);
  yield takeLatest(REQUEST_SUPPLIER_ONBOARD_DETAILS, requestSupplierOnboardDetails);
  yield takeLatest(REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS, requestUserAssociationAndBusinessCenterContacts);
  yield takeEvery(TRIGGER_UPDATE_SUPPLIER_CONTACT, updateSupplierContact);
}
