/**
 * @author hgun0888
 * @author ysum5308
 * */
import initialState from './initialState';
import {
  RECEIVE_ALL_VENDORS,
  REQUEST_ALL_VENDORS,
  RECEIVE_ALL_OPCOS
} from '../actions/vendorActions';
import {
  RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH,
  RECEIVE_ERROR_IN_VENDORS_FETCH
} from '../actions/errorDialogActions';

export default function vendorDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.vendor.data;
  }
  switch (type) {
    case REQUEST_ALL_VENDORS:
      return { ...state, loading: true };

    case RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_VENDORS_FETCH:
      return { ...state, loading: false };

    case RECEIVE_ALL_VENDORS:
      return { ...state, suvcMappingList: data.suvcs, loading: false };
    case RECEIVE_ALL_OPCOS: {
      let globalOpcoList = [];
      let rdcOpcoList = [];
      let opcoList = [];
      if (data.length > 0) {
        opcoList = data.filter((opco) => (opco.co3Oc.trim() !== '')).map((obj) => ({
          label: `${obj.co3Oc} - ${obj.briefName}`,
          value: obj
        }));
        globalOpcoList = opcoList.filter((opco) => opco.value.rdcFlag !== 'R');
        rdcOpcoList = opcoList.filter((opco) => opco.value.rdcFlag === 'R');
      }
      return {
        ...state, globalOpcoList, rdcOpcoList, loading: false
      };
    }
    default:
      return state;
  }
}
