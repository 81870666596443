import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to master data
 * @author skit1169
 */

class MasterDataProxy {
  static async fetchSites() {
    const config = {
      url: `${URLS.MASTER_DATA_URL}/houses`,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }
}

export default MasterDataProxy;
