/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * React Modules
 */
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storageSession from 'redux-persist/lib/storage/session';

/**
 * Custom Modules
 */
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

/**
 * Create the saga middleware
 */
const sagaMiddleware = createSagaMiddleware();
/**
 * Mount it on the Store
 */

const encryptor = createEncryptor({
  secretKey: 'soRzBN9qC8ivTEjpUkIWBelppkyVMW2Z'
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: [
    'userReducer',
  ],
  transforms: [encryptor]
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
/**
 * Run the SAGA
 */
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export {
  store,
  persistor,
};

export default store;
