/**
 * Created by ngan4172
 * */
import initialState from './initialState';
import {
  RECEIVE_SAS_FD_DETAILS
} from '../actions/agreementActions';

export default function sasFDDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.sasFDDetails;
  }

  if (type === RECEIVE_SAS_FD_DETAILS) {
    return { ...state, ...data };
  }

  return state;
}
