import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to utilities
 *
 * @author fris4874
 */
class UtilitiesProxy {
  static async getEarnedIncomeMappings(data) {
    const url = URLS.EARNED_INCOME_MAPPINGS_REQUEST_URL;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        house_type: data.serverType,
        business_center: data.businessCenter
      }
    };
    const response = await axios(config);
    return response.data;
  }
}

export default UtilitiesProxy;
