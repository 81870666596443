import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import BFFProxy from './bffProxy';
import axiosWithPayloadInterceptor from '../interceptor/axiosWithPayloadInterceptor';
import {
  HTTP_METHODS,
  HTTP_STATUS,
  URLS,
  REQUEST_TYPES,
  FILE_TYPES,
} from '../helpers/constants';
import {
  S3_FILE_UPLOAD_ERROR,
  INVALID_WORKSPACE_ATTACHMENT_TYPE
} from '../helpers/messageConstant';

/**
 * Fetch data from Workspace API
 *
 * @author ysum5308
 */
class WorkspaceProxy {
  static async fetchAllSupplierSupportRequestTypes() {
    const url = `${URLS.WORKSPACE_SUPPLIER_SUPPORT_REQUEST_TYPES_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async uploadWorkspaceAttachments(type, attachments) {
    const promises = [];
    const requestId = uuidv4().toString();
    attachments.forEach((attachment) => {
      promises.push(WorkspaceProxy.uploadWorkspaceAttachment(type, requestId, attachment));
    });
    return Promise.all(promises);
  }

  static handleError(errorMessage) {
    return Promise.reject(new Error(errorMessage));
  }

  static async uploadWorkspaceAttachment(type, requestId, attachment) {
    try {
      let file;
      const uploadedAttachment = {};
      if (type === REQUEST_TYPES.SUPPLIER_SUPPORT_REQUEST || type === FILE_TYPES.GENERAL_WORKSPACE_ATTACHMENT) {
        file = attachment;
      } else if (type === REQUEST_TYPES.VENDOR_COST_CATALOG) {
        file = attachment.data;
        uploadedAttachment.type = attachment.type;
      } else {
        return this.handleError(INVALID_WORKSPACE_ATTACHMENT_TYPE);
      }
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(type, file.name, { subFileType: uploadedAttachment.type, requestId });
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        uploadedAttachment.directory = signedUrlResponse.data.path;
        uploadedAttachment.actualFileName = signedUrlResponse.data.fileName;
        uploadedAttachment.displayFileName = file.name;
        return uploadedAttachment;
      }
      return this.handleError(S3_FILE_UPLOAD_ERROR);
    } catch (e) {
      return this.handleError(e.message);
    }
  }

  static async fetchAllHelpTopics() {
    const url = `${URLS.WORKSPACE_HELP_TOPICS_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchHelpTopicContent(topicId) {
    const url = `${URLS.WORKSPACE_HELP_TOPIC_DETAILS_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        topic_id: topicId
      }
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchRecentActivityData(data) {
    const { startFrom, fetchActiveRequests, groups } = data;
    const url = `${URLS.WORKSPACE_RECENT_ACTIVITIES_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        startFrom,
        fetchActiveRequests,
        groups: groups.join(',')
      }
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async fetchRequestDetails(requestId) {
    const url = `${URLS.WORKSPACE_REQUEST_DETAILS_URL}/details`;
    const config = {
      url,
      method: HTTP_METHODS.GET,
      params: {
        request_id: requestId
      }
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async submitWorkspaceRequest(data) {
    const config = {
      url: `${URLS.WORKSPACE_REQUEST_SUBMISSION_URL}`,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async updateRequestStatus(data) {
    const config = {
      url: `${URLS.WORKSPACE_REQUEST_DETAILS_URL}/status/update`,
      method: HTTP_METHODS.PATCH,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async submitRequestActionChange(data) {
    const config = {
      url: `${URLS.WORKSPACE_REQUEST_DETAILS_URL}/actions`,
      method: HTTP_METHODS.POST,
      params: {
        business_process_id: data.businessProcessId
      },
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async addWorkspaceRequestComment(data) {
    const config = {
      url: `${URLS.WORKSPACE_REQUEST_DETAILS_URL}/comment/submit`,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchWorkspaceSearchData(data) {
    const url = `${URLS.WORKSPACE_REQUEST_DETAILS_URL}/search`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }
}

export default WorkspaceProxy;
